/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #d7282f;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #ffffff;
  --bs-gray: #e3e3e3;
  --bs-gray-dark: #e0e0e0;
  --bs-gray-100: #9a9ea6;
  --bs-gray-200: #f6f5f5;
  --bs-gray-300: #d0cfcd;
  --bs-gray-400: #efefef;
  --bs-gray-500: #2a2644;
  --bs-gray-600: #e3e3e3;
  --bs-gray-700: #495057;
  --bs-gray-800: #e0e0e0;
  --bs-gray-900: #c4c4c4;
  --bs-primary: #0a3255;
  --bs-primary-light: rgba(10, 50, 85, 0.5);
  --bs-secondary: #da262e;
  --bs-secondary-light: rgba(218, 38, 46, 0.5);
  --bs-westar: #ddd8ce;
  --bs-swirl: #d7cdca;
  --bs-light: #fbfbfb;
  --bs-success: #1acf82;
  --bs-gray-100: #9a9ea6;
  --bs-gray-200: #f6f5f5;
  --bs-gray-300: #d0cfcd;
  --bs-gray-400: #efefef;
  --bs-gray-500: #2a2644;
  --bs-gray-600: #e3e3e3;
  --bs-gray-800: #e0e0e0;
  --bs-gray-900: #c4c4c4;
  --bs-body: #0a3255;
  --bs-info: #60b8d0;
  --bs-info-100: #0083c1;
  --bs-info-200: #52b1c5;
  --bs-info-300: #97d1dc;
  --bs-info-400: #40a7be;
  --bs-warning: #d3ad57;
  --bs-danger: #d7282f;
  --bs-dark: #e9e9e9;
  --bs-black: #000;
  --bs-red: #d7282f;
  --bs-white: #ffffff;
  --bs-transparent: transparent;
  --bs-primary-rgb: 10, 50, 85;
  --bs-primary-light-rgb: 10, 50, 85;
  --bs-secondary-rgb: 218, 38, 46;
  --bs-secondary-light-rgb: 218, 38, 46;
  --bs-westar-rgb: 221, 216, 206;
  --bs-swirl-rgb: 215, 205, 202;
  --bs-light-rgb: 251, 251, 251;
  --bs-success-rgb: 26, 207, 130;
  --bs-gray-100-rgb: 154, 158, 166;
  --bs-gray-200-rgb: 246, 245, 245;
  --bs-gray-300-rgb: 208, 207, 205;
  --bs-gray-400-rgb: 239, 239, 239;
  --bs-gray-500-rgb: 42, 38, 68;
  --bs-gray-600-rgb: 227, 227, 227;
  --bs-gray-800-rgb: 224, 224, 224;
  --bs-gray-900-rgb: 196, 196, 196;
  --bs-body-rgb: 10, 50, 85;
  --bs-info-rgb: 96, 184, 208;
  --bs-info-100-rgb: 0, 131, 193;
  --bs-info-200-rgb: 82, 177, 197;
  --bs-info-300-rgb: 151, 209, 220;
  --bs-info-400-rgb: 64, 167, 190;
  --bs-warning-rgb: 211, 173, 87;
  --bs-danger-rgb: 215, 40, 47;
  --bs-dark-rgb: 233, 233, 233;
  --bs-black-rgb: 0, 0, 0;
  --bs-red-rgb: 215, 40, 47;
  --bs-white-rgb: 255, 255, 255;
  --bs-transparent-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 10, 50, 85;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "arial-regular", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: arial-regular, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #0a3255;
  --bs-body-bg: #ffffff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d0cfcd;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50px;
  --bs-link-color: #0a3255;
  --bs-link-hover-color: #082844;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0.6667rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.33335rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #e3e3e3;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 15px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.33335rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.33335rem;
}

.g-3px,
.gx-3px {
  --bs-gutter-x: 3px;
}

.g-3px,
.gy-3px {
  --bs-gutter-y: 3px;
}

.g-4px,
.gx-4px {
  --bs-gutter-x: 4px;
}

.g-4px,
.gy-4px {
  --bs-gutter-y: 4px;
}

.g-8px,
.gx-8px {
  --bs-gutter-x: 8px;
}

.g-8px,
.gy-8px {
  --bs-gutter-y: 8px;
}

.g-12px,
.gx-12px {
  --bs-gutter-x: 12px;
}

.g-12px,
.gy-12px {
  --bs-gutter-y: 12px;
}

.g-15px,
.gx-15px {
  --bs-gutter-x: 15px;
}

.g-15px,
.gy-15px {
  --bs-gutter-y: 15px;
}

.g-16px,
.gx-16px {
  --bs-gutter-x: 16px;
}

.g-16px,
.gy-16px {
  --bs-gutter-y: 16px;
}

.g-18px,
.gx-18px {
  --bs-gutter-x: 18px;
}

.g-18px,
.gy-18px {
  --bs-gutter-y: 18px;
}

.g-24px,
.gx-24px {
  --bs-gutter-x: 24px;
}

.g-24px,
.gy-24px {
  --bs-gutter-y: 24px;
}

.g-25px,
.gx-25px {
  --bs-gutter-x: 25px;
}

.g-25px,
.gy-25px {
  --bs-gutter-y: 25px;
}

.g-30px,
.gx-30px {
  --bs-gutter-x: 30px;
}

.g-30px,
.gy-30px {
  --bs-gutter-y: 30px;
}

.g-32px,
.gx-32px {
  --bs-gutter-x: 32px;
}

.g-32px,
.gy-32px {
  --bs-gutter-y: 32px;
}

.g-57px,
.gx-57px {
  --bs-gutter-x: 57px;
}

.g-57px,
.gy-57px {
  --bs-gutter-y: 57px;
}

.g-n15,
.gx-n15 {
  --bs-gutter-x: -15px;
}

.g-n15,
.gy-n15 {
  --bs-gutter-y: -15px;
}

.g-n5,
.gx-n5 {
  --bs-gutter-x: -5px;
}

.g-n5,
.gy-n5 {
  --bs-gutter-y: -5px;
}

.g-3vh,
.gx-3vh {
  --bs-gutter-x: 3vh;
}

.g-3vh,
.gy-3vh {
  --bs-gutter-y: 3vh;
}

.g-10vh,
.gx-10vh {
  --bs-gutter-x: 10vh;
}

.g-10vh,
.gy-10vh {
  --bs-gutter-y: 10vh;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.6667rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.6667rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.3334rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.3334rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2.0001rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2.0001rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.6668rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.6668rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3.3335rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3.3335rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.0002rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.0002rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4.6669rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4.6669rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 5.3336rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 5.3336rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 6.0003rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 6.0003rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 6.667rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 6.667rem;
}

.g-gutter,
.gx-gutter {
  --bs-gutter-x: 24px;
}

.g-gutter,
.gy-gutter {
  --bs-gutter-y: 24px;
}

.g-out-container,
.gx-out-container {
  --bs-gutter-x: calc(50% - 50vw);
}

.g-out-container,
.gy-out-container {
  --bs-gutter-y: calc(50% - 50vw);
}
.font-sans-serif {
  font-family: "arial-regular", sans-serif !important;
}

.font-sans-serif-italic {
  font-family: "arial-italic", sans-serif !important;
}

.font-sans-serif-bolditalic {
  font-family: "arial-bolditalic", sans-serif !important;
}

.font-sans-serif-bold {
  font-family: "arial-bold", sans-serif !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #0a3255 !important;
}

.bg-primary-light {
  --bs-bg-opacity: 1;
  background-color: rgba(10, 50, 85, 0.5) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: #da262e !important;
}

.bg-secondary-light {
  --bs-bg-opacity: 1;
  background-color: rgba(218, 38, 46, 0.5) !important;
}

.bg-westar {
  --bs-bg-opacity: 1;
  background-color: #ddd8ce !important;
}

.bg-swirl {
  --bs-bg-opacity: 1;
  background-color: #d7cdca !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: #fbfbfb !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: #1acf82 !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: #9a9ea6 !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: #f6f5f5 !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: #d0cfcd !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: #efefef !important;
}

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: #2a2644 !important;
}

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: #e3e3e3 !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: #e0e0e0 !important;
}

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: #c4c4c4 !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: #0a3255 !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: #60b8d0 !important;
}

.bg-info-100 {
  --bs-bg-opacity: 1;
  background-color: #0083c1 !important;
}

.bg-info-200 {
  --bs-bg-opacity: 1;
  background-color: #52b1c5 !important;
}

.bg-info-300 {
  --bs-bg-opacity: 1;
  background-color: #97d1dc !important;
}

.bg-info-400 {
  --bs-bg-opacity: 1;
  background-color: #40a7be !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: #d3ad57 !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: #d7282f !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: #e9e9e9 !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: #d7282f !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #ffffff !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.text-primary {
  color: #0a3255 !important;
}

.text-primary-light {
  color: rgba(10, 50, 85, 0.5) !important;
}

.text-secondary {
  color: #da262e !important;
}

.text-secondary-light {
  color: rgba(218, 38, 46, 0.5) !important;
}

.text-westar {
  color: #ddd8ce !important;
}

.text-swirl {
  color: #d7cdca !important;
}

.text-light {
  color: #fbfbfb !important;
}

.text-success {
  color: #1acf82 !important;
}

.text-gray-100 {
  color: #9a9ea6 !important;
}

.text-gray-200 {
  color: #f6f5f5 !important;
}

.text-gray-300 {
  color: #d0cfcd !important;
}

.text-gray-400 {
  color: #efefef !important;
}

.text-gray-500 {
  color: #2a2644 !important;
}

.text-gray-600 {
  color: #e3e3e3 !important;
}

.text-gray-800 {
  color: #e0e0e0 !important;
}

.text-gray-900 {
  color: #c4c4c4 !important;
}

.text-body {
  color: #0a3255 !important;
}

.text-info {
  color: #60b8d0 !important;
}

.text-info-100 {
  color: #0083c1 !important;
}

.text-info-200 {
  color: #52b1c5 !important;
}

.text-info-300 {
  color: #97d1dc !important;
}

.text-info-400 {
  color: #40a7be !important;
}

.text-warning {
  color: #d3ad57 !important;
}

.text-danger {
  color: #d7282f !important;
}

.text-dark {
  color: #e9e9e9 !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #d7282f !important;
}

.text-white {
  color: #ffffff !important;
}

.text-transparent {
  color: transparent !important;
}

.m-sm {
  margin: 0.33335rem !important;
}

.m-3px {
  margin: 3px !important;
}

.m-4px {
  margin: 4px !important;
}

.m-8px {
  margin: 8px !important;
}

.m-12px {
  margin: 12px !important;
}

.m-15px {
  margin: 15px !important;
}

.m-16px {
  margin: 16px !important;
}

.m-18px {
  margin: 18px !important;
}

.m-24px {
  margin: 24px !important;
}

.m-25px {
  margin: 25px !important;
}

.m-30px {
  margin: 30px !important;
}

.m-32px {
  margin: 32px !important;
}

.m-57px {
  margin: 57px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n5 {
  margin: -5px !important;
}

.m-3vh {
  margin: 3vh !important;
}

.m-10vh {
  margin: 10vh !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.6667rem !important;
}

.m-2 {
  margin: 1.3334rem !important;
}

.m-3 {
  margin: 2.0001rem !important;
}

.m-4 {
  margin: 2.6668rem !important;
}

.m-5 {
  margin: 3.3335rem !important;
}

.m-6 {
  margin: 4.0002rem !important;
}

.m-7 {
  margin: 4.6669rem !important;
}

.m-8 {
  margin: 5.3336rem !important;
}

.m-9 {
  margin: 6.0003rem !important;
}

.m-10 {
  margin: 6.667rem !important;
}

.m-gutter {
  margin: 24px !important;
}

.m-out-container {
  margin: calc(50% - 50vw) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-sm {
  margin-right: 0.33335rem !important;
  margin-left: 0.33335rem !important;
}

.mx-3px {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-4px {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-8px {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-12px {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.mx-15px {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-16px {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-18px {
  margin-right: 18px !important;
  margin-left: 18px !important;
}

.mx-24px {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-25px {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-30px {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-32px {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-57px {
  margin-right: 57px !important;
  margin-left: 57px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.mx-3vh {
  margin-right: 3vh !important;
  margin-left: 3vh !important;
}

.mx-10vh {
  margin-right: 10vh !important;
  margin-left: 10vh !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.6667rem !important;
  margin-left: 0.6667rem !important;
}

.mx-2 {
  margin-right: 1.3334rem !important;
  margin-left: 1.3334rem !important;
}

.mx-3 {
  margin-right: 2.0001rem !important;
  margin-left: 2.0001rem !important;
}

.mx-4 {
  margin-right: 2.6668rem !important;
  margin-left: 2.6668rem !important;
}

.mx-5 {
  margin-right: 3.3335rem !important;
  margin-left: 3.3335rem !important;
}

.mx-6 {
  margin-right: 4.0002rem !important;
  margin-left: 4.0002rem !important;
}

.mx-7 {
  margin-right: 4.6669rem !important;
  margin-left: 4.6669rem !important;
}

.mx-8 {
  margin-right: 5.3336rem !important;
  margin-left: 5.3336rem !important;
}

.mx-9 {
  margin-right: 6.0003rem !important;
  margin-left: 6.0003rem !important;
}

.mx-10 {
  margin-right: 6.667rem !important;
  margin-left: 6.667rem !important;
}

.mx-gutter {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-out-container {
  margin-right: calc(50% - 50vw) !important;
  margin-left: calc(50% - 50vw) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-sm {
  margin-top: 0.33335rem !important;
  margin-bottom: 0.33335rem !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-12px {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-18px {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.my-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30px {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-32px {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-57px {
  margin-top: 57px !important;
  margin-bottom: 57px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.my-3vh {
  margin-top: 3vh !important;
  margin-bottom: 3vh !important;
}

.my-10vh {
  margin-top: 10vh !important;
  margin-bottom: 10vh !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.6667rem !important;
  margin-bottom: 0.6667rem !important;
}

.my-2 {
  margin-top: 1.3334rem !important;
  margin-bottom: 1.3334rem !important;
}

.my-3 {
  margin-top: 2.0001rem !important;
  margin-bottom: 2.0001rem !important;
}

.my-4 {
  margin-top: 2.6668rem !important;
  margin-bottom: 2.6668rem !important;
}

.my-5 {
  margin-top: 3.3335rem !important;
  margin-bottom: 3.3335rem !important;
}

.my-6 {
  margin-top: 4.0002rem !important;
  margin-bottom: 4.0002rem !important;
}

.my-7 {
  margin-top: 4.6669rem !important;
  margin-bottom: 4.6669rem !important;
}

.my-8 {
  margin-top: 5.3336rem !important;
  margin-bottom: 5.3336rem !important;
}

.my-9 {
  margin-top: 6.0003rem !important;
  margin-bottom: 6.0003rem !important;
}

.my-10 {
  margin-top: 6.667rem !important;
  margin-bottom: 6.667rem !important;
}

.my-gutter {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-out-container {
  margin-top: calc(50% - 50vw) !important;
  margin-bottom: calc(50% - 50vw) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-sm {
  margin-top: 0.33335rem !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-4px {
  margin-top: 4px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-12px {
  margin-top: 12px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-16px {
  margin-top: 16px !important;
}

.mt-18px {
  margin-top: 18px !important;
}

.mt-24px {
  margin-top: 24px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-32px {
  margin-top: 32px !important;
}

.mt-57px {
  margin-top: 57px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n5 {
  margin-top: -5px !important;
}

.mt-3vh {
  margin-top: 3vh !important;
}

.mt-10vh {
  margin-top: 10vh !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.6667rem !important;
}

.mt-2 {
  margin-top: 1.3334rem !important;
}

.mt-3 {
  margin-top: 2.0001rem !important;
}

.mt-4 {
  margin-top: 2.6668rem !important;
}

.mt-5 {
  margin-top: 3.3335rem !important;
}

.mt-6 {
  margin-top: 4.0002rem !important;
}

.mt-7 {
  margin-top: 4.6669rem !important;
}

.mt-8 {
  margin-top: 5.3336rem !important;
}

.mt-9 {
  margin-top: 6.0003rem !important;
}

.mt-10 {
  margin-top: 6.667rem !important;
}

.mt-gutter {
  margin-top: 24px !important;
}

.mt-out-container {
  margin-top: calc(50% - 50vw) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-sm {
  margin-right: 0.33335rem !important;
}

.me-3px {
  margin-right: 3px !important;
}

.me-4px {
  margin-right: 4px !important;
}

.me-8px {
  margin-right: 8px !important;
}

.me-12px {
  margin-right: 12px !important;
}

.me-15px {
  margin-right: 15px !important;
}

.me-16px {
  margin-right: 16px !important;
}

.me-18px {
  margin-right: 18px !important;
}

.me-24px {
  margin-right: 24px !important;
}

.me-25px {
  margin-right: 25px !important;
}

.me-30px {
  margin-right: 30px !important;
}

.me-32px {
  margin-right: 32px !important;
}

.me-57px {
  margin-right: 57px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n5 {
  margin-right: -5px !important;
}

.me-3vh {
  margin-right: 3vh !important;
}

.me-10vh {
  margin-right: 10vh !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.6667rem !important;
}

.me-2 {
  margin-right: 1.3334rem !important;
}

.me-3 {
  margin-right: 2.0001rem !important;
}

.me-4 {
  margin-right: 2.6668rem !important;
}

.me-5 {
  margin-right: 3.3335rem !important;
}

.me-6 {
  margin-right: 4.0002rem !important;
}

.me-7 {
  margin-right: 4.6669rem !important;
}

.me-8 {
  margin-right: 5.3336rem !important;
}

.me-9 {
  margin-right: 6.0003rem !important;
}

.me-10 {
  margin-right: 6.667rem !important;
}

.me-gutter {
  margin-right: 24px !important;
}

.me-out-container {
  margin-right: calc(50% - 50vw) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-sm {
  margin-bottom: 0.33335rem !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.mb-12px {
  margin-bottom: 12px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mb-18px {
  margin-bottom: 18px !important;
}

.mb-24px {
  margin-bottom: 24px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-32px {
  margin-bottom: 32px !important;
}

.mb-57px {
  margin-bottom: 57px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n5 {
  margin-bottom: -5px !important;
}

.mb-3vh {
  margin-bottom: 3vh !important;
}

.mb-10vh {
  margin-bottom: 10vh !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.6667rem !important;
}

.mb-2 {
  margin-bottom: 1.3334rem !important;
}

.mb-3 {
  margin-bottom: 2.0001rem !important;
}

.mb-4 {
  margin-bottom: 2.6668rem !important;
}

.mb-5 {
  margin-bottom: 3.3335rem !important;
}

.mb-6 {
  margin-bottom: 4.0002rem !important;
}

.mb-7 {
  margin-bottom: 4.6669rem !important;
}

.mb-8 {
  margin-bottom: 5.3336rem !important;
}

.mb-9 {
  margin-bottom: 6.0003rem !important;
}

.mb-10 {
  margin-bottom: 6.667rem !important;
}

.mb-gutter {
  margin-bottom: 24px !important;
}

.mb-out-container {
  margin-bottom: calc(50% - 50vw) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-sm {
  margin-left: 0.33335rem !important;
}

.ms-3px {
  margin-left: 3px !important;
}

.ms-4px {
  margin-left: 4px !important;
}

.ms-8px {
  margin-left: 8px !important;
}

.ms-12px {
  margin-left: 12px !important;
}

.ms-15px {
  margin-left: 15px !important;
}

.ms-16px {
  margin-left: 16px !important;
}

.ms-18px {
  margin-left: 18px !important;
}

.ms-24px {
  margin-left: 24px !important;
}

.ms-25px {
  margin-left: 25px !important;
}

.ms-30px {
  margin-left: 30px !important;
}

.ms-32px {
  margin-left: 32px !important;
}

.ms-57px {
  margin-left: 57px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n5 {
  margin-left: -5px !important;
}

.ms-3vh {
  margin-left: 3vh !important;
}

.ms-10vh {
  margin-left: 10vh !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.6667rem !important;
}

.ms-2 {
  margin-left: 1.3334rem !important;
}

.ms-3 {
  margin-left: 2.0001rem !important;
}

.ms-4 {
  margin-left: 2.6668rem !important;
}

.ms-5 {
  margin-left: 3.3335rem !important;
}

.ms-6 {
  margin-left: 4.0002rem !important;
}

.ms-7 {
  margin-left: 4.6669rem !important;
}

.ms-8 {
  margin-left: 5.3336rem !important;
}

.ms-9 {
  margin-left: 6.0003rem !important;
}

.ms-10 {
  margin-left: 6.667rem !important;
}

.ms-gutter {
  margin-left: 24px !important;
}

.ms-out-container {
  margin-left: calc(50% - 50vw) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-sm {
  padding: 0.33335rem !important;
}

.p-3px {
  padding: 3px !important;
}

.p-4px {
  padding: 4px !important;
}

.p-8px {
  padding: 8px !important;
}

.p-12px {
  padding: 12px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-16px {
  padding: 16px !important;
}

.p-18px {
  padding: 18px !important;
}

.p-24px {
  padding: 24px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-30px {
  padding: 30px !important;
}

.p-32px {
  padding: 32px !important;
}

.p-57px {
  padding: 57px !important;
}

.p-n15 {
  padding: -15px !important;
}

.p-n5 {
  padding: -5px !important;
}

.p-3vh {
  padding: 3vh !important;
}

.p-10vh {
  padding: 10vh !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.6667rem !important;
}

.p-2 {
  padding: 1.3334rem !important;
}

.p-3 {
  padding: 2.0001rem !important;
}

.p-4 {
  padding: 2.6668rem !important;
}

.p-5 {
  padding: 3.3335rem !important;
}

.p-6 {
  padding: 4.0002rem !important;
}

.p-7 {
  padding: 4.6669rem !important;
}

.p-8 {
  padding: 5.3336rem !important;
}

.p-9 {
  padding: 6.0003rem !important;
}

.p-10 {
  padding: 6.667rem !important;
}

.p-gutter {
  padding: 24px !important;
}

.p-out-container {
  padding: calc(50% - 50vw) !important;
}

.px-sm {
  padding-right: 0.33335rem !important;
  padding-left: 0.33335rem !important;
}

.px-3px {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.px-4px {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-8px {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-12px {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-15px {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-16px {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-18px {
  padding-right: 18px !important;
  padding-left: 18px !important;
}

.px-24px {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-25px {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.px-30px {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.px-32px {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-57px {
  padding-right: 57px !important;
  padding-left: 57px !important;
}

.px-n15 {
  padding-right: -15px !important;
  padding-left: -15px !important;
}

.px-n5 {
  padding-right: -5px !important;
  padding-left: -5px !important;
}

.px-3vh {
  padding-right: 3vh !important;
  padding-left: 3vh !important;
}

.px-10vh {
  padding-right: 10vh !important;
  padding-left: 10vh !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.6667rem !important;
  padding-left: 0.6667rem !important;
}

.px-2 {
  padding-right: 1.3334rem !important;
  padding-left: 1.3334rem !important;
}

.px-3 {
  padding-right: 2.0001rem !important;
  padding-left: 2.0001rem !important;
}

.px-4 {
  padding-right: 2.6668rem !important;
  padding-left: 2.6668rem !important;
}

.px-5 {
  padding-right: 3.3335rem !important;
  padding-left: 3.3335rem !important;
}

.px-6 {
  padding-right: 4.0002rem !important;
  padding-left: 4.0002rem !important;
}

.px-7 {
  padding-right: 4.6669rem !important;
  padding-left: 4.6669rem !important;
}

.px-8 {
  padding-right: 5.3336rem !important;
  padding-left: 5.3336rem !important;
}

.px-9 {
  padding-right: 6.0003rem !important;
  padding-left: 6.0003rem !important;
}

.px-10 {
  padding-right: 6.667rem !important;
  padding-left: 6.667rem !important;
}

.px-gutter {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-out-container {
  padding-right: calc(50% - 50vw) !important;
  padding-left: calc(50% - 50vw) !important;
}

.py-sm {
  padding-top: 0.33335rem !important;
  padding-bottom: 0.33335rem !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-16px {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-18px {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-32px {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-57px {
  padding-top: 57px !important;
  padding-bottom: 57px !important;
}

.py-n15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.py-n5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important;
}

.py-3vh {
  padding-top: 3vh !important;
  padding-bottom: 3vh !important;
}

.py-10vh {
  padding-top: 10vh !important;
  padding-bottom: 10vh !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.6667rem !important;
  padding-bottom: 0.6667rem !important;
}

.py-2 {
  padding-top: 1.3334rem !important;
  padding-bottom: 1.3334rem !important;
}

.py-3 {
  padding-top: 2.0001rem !important;
  padding-bottom: 2.0001rem !important;
}

.py-4 {
  padding-top: 2.6668rem !important;
  padding-bottom: 2.6668rem !important;
}

.py-5 {
  padding-top: 3.3335rem !important;
  padding-bottom: 3.3335rem !important;
}

.py-6 {
  padding-top: 4.0002rem !important;
  padding-bottom: 4.0002rem !important;
}

.py-7 {
  padding-top: 4.6669rem !important;
  padding-bottom: 4.6669rem !important;
}

.py-8 {
  padding-top: 5.3336rem !important;
  padding-bottom: 5.3336rem !important;
}

.py-9 {
  padding-top: 6.0003rem !important;
  padding-bottom: 6.0003rem !important;
}

.py-10 {
  padding-top: 6.667rem !important;
  padding-bottom: 6.667rem !important;
}

.py-gutter {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-out-container {
  padding-top: calc(50% - 50vw) !important;
  padding-bottom: calc(50% - 50vw) !important;
}

.pt-sm {
  padding-top: 0.33335rem !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-4px {
  padding-top: 4px !important;
}

.pt-8px {
  padding-top: 8px !important;
}

.pt-12px {
  padding-top: 12px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-16px {
  padding-top: 16px !important;
}

.pt-18px {
  padding-top: 18px !important;
}

.pt-24px {
  padding-top: 24px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-30px {
  padding-top: 30px !important;
}

.pt-32px {
  padding-top: 32px !important;
}

.pt-57px {
  padding-top: 57px !important;
}

.pt-n15 {
  padding-top: -15px !important;
}

.pt-n5 {
  padding-top: -5px !important;
}

.pt-3vh {
  padding-top: 3vh !important;
}

.pt-10vh {
  padding-top: 10vh !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.6667rem !important;
}

.pt-2 {
  padding-top: 1.3334rem !important;
}

.pt-3 {
  padding-top: 2.0001rem !important;
}

.pt-4 {
  padding-top: 2.6668rem !important;
}

.pt-5 {
  padding-top: 3.3335rem !important;
}

.pt-6 {
  padding-top: 4.0002rem !important;
}

.pt-7 {
  padding-top: 4.6669rem !important;
}

.pt-8 {
  padding-top: 5.3336rem !important;
}

.pt-9 {
  padding-top: 6.0003rem !important;
}

.pt-10 {
  padding-top: 6.667rem !important;
}

.pt-gutter {
  padding-top: 24px !important;
}

.pt-out-container {
  padding-top: calc(50% - 50vw) !important;
}

.pe-sm {
  padding-right: 0.33335rem !important;
}

.pe-3px {
  padding-right: 3px !important;
}

.pe-4px {
  padding-right: 4px !important;
}

.pe-8px {
  padding-right: 8px !important;
}

.pe-12px {
  padding-right: 12px !important;
}

.pe-15px {
  padding-right: 15px !important;
}

.pe-16px {
  padding-right: 16px !important;
}

.pe-18px {
  padding-right: 18px !important;
}

.pe-24px {
  padding-right: 24px !important;
}

.pe-25px {
  padding-right: 25px !important;
}

.pe-30px {
  padding-right: 30px !important;
}

.pe-32px {
  padding-right: 32px !important;
}

.pe-57px {
  padding-right: 57px !important;
}

.pe-n15 {
  padding-right: -15px !important;
}

.pe-n5 {
  padding-right: -5px !important;
}

.pe-3vh {
  padding-right: 3vh !important;
}

.pe-10vh {
  padding-right: 10vh !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.6667rem !important;
}

.pe-2 {
  padding-right: 1.3334rem !important;
}

.pe-3 {
  padding-right: 2.0001rem !important;
}

.pe-4 {
  padding-right: 2.6668rem !important;
}

.pe-5 {
  padding-right: 3.3335rem !important;
}

.pe-6 {
  padding-right: 4.0002rem !important;
}

.pe-7 {
  padding-right: 4.6669rem !important;
}

.pe-8 {
  padding-right: 5.3336rem !important;
}

.pe-9 {
  padding-right: 6.0003rem !important;
}

.pe-10 {
  padding-right: 6.667rem !important;
}

.pe-gutter {
  padding-right: 24px !important;
}

.pe-out-container {
  padding-right: calc(50% - 50vw) !important;
}

.pb-sm {
  padding-bottom: 0.33335rem !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-4px {
  padding-bottom: 4px !important;
}

.pb-8px {
  padding-bottom: 8px !important;
}

.pb-12px {
  padding-bottom: 12px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-16px {
  padding-bottom: 16px !important;
}

.pb-18px {
  padding-bottom: 18px !important;
}

.pb-24px {
  padding-bottom: 24px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pb-32px {
  padding-bottom: 32px !important;
}

.pb-57px {
  padding-bottom: 57px !important;
}

.pb-n15 {
  padding-bottom: -15px !important;
}

.pb-n5 {
  padding-bottom: -5px !important;
}

.pb-3vh {
  padding-bottom: 3vh !important;
}

.pb-10vh {
  padding-bottom: 10vh !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.6667rem !important;
}

.pb-2 {
  padding-bottom: 1.3334rem !important;
}

.pb-3 {
  padding-bottom: 2.0001rem !important;
}

.pb-4 {
  padding-bottom: 2.6668rem !important;
}

.pb-5 {
  padding-bottom: 3.3335rem !important;
}

.pb-6 {
  padding-bottom: 4.0002rem !important;
}

.pb-7 {
  padding-bottom: 4.6669rem !important;
}

.pb-8 {
  padding-bottom: 5.3336rem !important;
}

.pb-9 {
  padding-bottom: 6.0003rem !important;
}

.pb-10 {
  padding-bottom: 6.667rem !important;
}

.pb-gutter {
  padding-bottom: 24px !important;
}

.pb-out-container {
  padding-bottom: calc(50% - 50vw) !important;
}

.ps-sm {
  padding-left: 0.33335rem !important;
}

.ps-3px {
  padding-left: 3px !important;
}

.ps-4px {
  padding-left: 4px !important;
}

.ps-8px {
  padding-left: 8px !important;
}

.ps-12px {
  padding-left: 12px !important;
}

.ps-15px {
  padding-left: 15px !important;
}

.ps-16px {
  padding-left: 16px !important;
}

.ps-18px {
  padding-left: 18px !important;
}

.ps-24px {
  padding-left: 24px !important;
}

.ps-25px {
  padding-left: 25px !important;
}

.ps-30px {
  padding-left: 30px !important;
}

.ps-32px {
  padding-left: 32px !important;
}

.ps-57px {
  padding-left: 57px !important;
}

.ps-n15 {
  padding-left: -15px !important;
}

.ps-n5 {
  padding-left: -5px !important;
}

.ps-3vh {
  padding-left: 3vh !important;
}

.ps-10vh {
  padding-left: 10vh !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.6667rem !important;
}

.ps-2 {
  padding-left: 1.3334rem !important;
}

.ps-3 {
  padding-left: 2.0001rem !important;
}

.ps-4 {
  padding-left: 2.6668rem !important;
}

.ps-5 {
  padding-left: 3.3335rem !important;
}

.ps-6 {
  padding-left: 4.0002rem !important;
}

.ps-7 {
  padding-left: 4.6669rem !important;
}

.ps-8 {
  padding-left: 5.3336rem !important;
}

.ps-9 {
  padding-left: 6.0003rem !important;
}

.ps-10 {
  padding-left: 6.667rem !important;
}

.ps-gutter {
  padding-left: 24px !important;
}

.ps-out-container {
  padding-left: calc(50% - 50vw) !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-sm {
  gap: 0.33335rem !important;
}

.gap-3px {
  gap: 3px !important;
}

.gap-4px {
  gap: 4px !important;
}

.gap-8px {
  gap: 8px !important;
}

.gap-12px {
  gap: 12px !important;
}

.gap-15px {
  gap: 15px !important;
}

.gap-16px {
  gap: 16px !important;
}

.gap-18px {
  gap: 18px !important;
}

.gap-24px {
  gap: 24px !important;
}

.gap-25px {
  gap: 25px !important;
}

.gap-30px {
  gap: 30px !important;
}

.gap-32px {
  gap: 32px !important;
}

.gap-57px {
  gap: 57px !important;
}

.gap-n15 {
  gap: -15px !important;
}

.gap-n5 {
  gap: -5px !important;
}

.gap-3vh {
  gap: 3vh !important;
}

.gap-10vh {
  gap: 10vh !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.6667rem !important;
}

.gap-2 {
  gap: 1.3334rem !important;
}

.gap-3 {
  gap: 2.0001rem !important;
}

.gap-4 {
  gap: 2.6668rem !important;
}

.gap-5 {
  gap: 3.3335rem !important;
}

.gap-6 {
  gap: 4.0002rem !important;
}

.gap-7 {
  gap: 4.6669rem !important;
}

.gap-8 {
  gap: 5.3336rem !important;
}

.gap-9 {
  gap: 6.0003rem !important;
}

.gap-10 {
  gap: 6.667rem !important;
}

.gap-gutter {
  gap: 24px !important;
}

.gap-out-container {
  gap: calc(50% - 50vw) !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-25 {
  opacity: 0.25 !important;
}

.o-40 {
  opacity: 0.4 !important;
}

.o-50 {
  opacity: 0.5 !important;
}

.o-70 {
  opacity: 0.7 !important;
}

.o-75 {
  opacity: 0.75 !important;
}

.o-85 {
  opacity: 0.85 !important;
}

.o-100 {
  opacity: 1 !important;
}

.z-1 {
  z-index: 9 !important;
}

.z-2 {
  z-index: 99 !important;
}

.z-3 {
  z-index: 999 !important;
}

.z-4 {
  z-index: 1039 !important;
}

.z-5 {
  z-index: 9999 !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-full {
  -o-object-fit: full !important;
     object-fit: full !important;
}

.object-position-top {
  -o-object-position: top !important;
     object-position: top !important;
}

.object-position-center {
  -o-object-position: center !important;
     object-position: center !important;
}

.object-position-bottom {
  -o-object-position: bottom !important;
     object-position: bottom !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-65 {
  height: 65vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-64 {
  width: 64% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-82 {
  width: 82% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-20px {
  width: 20px !important;
}

.w-80px {
  width: 80px !important;
}

.w-icon {
  width: 24px !important;
}

.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.mw-10 {
  max-width: 10% !important;
}

.mw-20 {
  max-width: 20% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-60 {
  max-width: 60% !important;
}

.mw-70 {
  max-width: 70% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-290 {
  max-width: 290px !important;
}

.mw-618 {
  max-width: 618px !important;
}

.mw-824 {
  max-width: 824px !important;
}

.mw-500 {
  max-width: 500px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-20px {
  height: 20px !important;
}

.h-auto {
  height: auto !important;
}

.h-icon {
  height: 24px !important;
}

.h-400 {
  height: 400px !important;
}

.h-550 {
  height: 550px !important;
}

.h-fit {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.fs-1 {
  font-size: 3.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.5rem !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.875rem !important;
}

.fs-22 {
  font-size: 1.375rem !important;
}

.fs-40 {
  font-size: 2.5rem !important;
}

.fs-48 {
  font-size: 3rem !important;
}

.fs-lg {
  font-size: 3.375rem !important;
}

.fs-xl {
  font-size: 5rem !important;
}

.fs-sm {
  font-size: 0.75rem !important;
}

.fs-icon {
  font-size: 1.5625rem !important;
}

.transform-none {
  transform: none !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-md {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.order-unset {
  order: unset !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.border {
  border: 1px solid #d0cfcd !important;
}

.border-0 {
  border: 0 !important;
}

.border-2 {
  border: 2px solid !important;
}

.border-top {
  border-top: 1px solid #d0cfcd !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-start {
  border-left: 1px solid #d0cfcd !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-end {
  border-right: 1px solid #d0cfcd !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d0cfcd !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-primary {
  border-color: #0a3255 !important;
}

.border-primary-light {
  border-color: rgba(10, 50, 85, 0.5) !important;
}

.border-secondary {
  border-color: #da262e !important;
}

.border-secondary-light {
  border-color: rgba(218, 38, 46, 0.5) !important;
}

.border-westar {
  border-color: #ddd8ce !important;
}

.border-swirl {
  border-color: #d7cdca !important;
}

.border-light {
  border-color: #fbfbfb !important;
}

.border-success {
  border-color: #1acf82 !important;
}

.border-gray-100 {
  border-color: #9a9ea6 !important;
}

.border-gray-200 {
  border-color: #f6f5f5 !important;
}

.border-gray-300 {
  border-color: #d0cfcd !important;
}

.border-gray-400 {
  border-color: #efefef !important;
}

.border-gray-500 {
  border-color: #2a2644 !important;
}

.border-gray-600 {
  border-color: #e3e3e3 !important;
}

.border-gray-800 {
  border-color: #e0e0e0 !important;
}

.border-gray-900 {
  border-color: #c4c4c4 !important;
}

.border-body {
  border-color: #0a3255 !important;
}

.border-info {
  border-color: #60b8d0 !important;
}

.border-info-100 {
  border-color: #0083c1 !important;
}

.border-info-200 {
  border-color: #52b1c5 !important;
}

.border-info-300 {
  border-color: #97d1dc !important;
}

.border-info-400 {
  border-color: #40a7be !important;
}

.border-warning {
  border-color: #d3ad57 !important;
}

.border-danger {
  border-color: #d7282f !important;
}

.border-dark {
  border-color: #e9e9e9 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-red {
  border-color: #d7282f !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-transparent {
  border-color: transparent !important;
}

.shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow-small {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-large {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;
}

.top-30 {
  top: 30px !important;
}

.top-n10 {
  top: -10px !important;
}

.top-n20 {
  top: -20px !important;
}

.top-n8 {
  top: -8px !important;
}

.top-7 {
  top: 7px !important;
}

.top-13 {
  top: 13px !important;
}

.top-15 {
  top: 15px !important;
}

.top-n3 {
  top: -3px !important;
}

.top-5 {
  top: 5px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.bottom-n10 {
  bottom: -10px !important;
}

.bottom-n20 {
  bottom: -20px !important;
}

.bottom-n8 {
  bottom: -8px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.bottom-13 {
  bottom: 13px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.bottom-n3 {
  bottom: -3px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-10 {
  left: 10px !important;
}

.start-20 {
  left: 20px !important;
}

.start-30 {
  left: 30px !important;
}

.start-n10 {
  left: -10px !important;
}

.start-n20 {
  left: -20px !important;
}

.start-n8 {
  left: -8px !important;
}

.start-7 {
  left: 7px !important;
}

.start-13 {
  left: 13px !important;
}

.start-15 {
  left: 15px !important;
}

.start-n3 {
  left: -3px !important;
}

.start-5 {
  left: 5px !important;
}

.start-2 {
  left: 2px !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-10 {
  right: 10px !important;
}

.end-20 {
  right: 20px !important;
}

.end-30 {
  right: 30px !important;
}

.end-n10 {
  right: -10px !important;
}

.end-n20 {
  right: -20px !important;
}

.end-n8 {
  right: -8px !important;
}

.end-7 {
  right: 7px !important;
}

.end-13 {
  right: 13px !important;
}

.end-15 {
  right: 15px !important;
}

.end-n3 {
  right: -3px !important;
}

.end-5 {
  right: 5px !important;
}

.end-2 {
  right: 2px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 5px !important;
}

.rounded-2 {
  border-radius: 10px !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50px !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-top-2 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.rounded-top-3 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-end-2 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.rounded-end-3 {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.rounded-start-2 {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.rounded-start-3 {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: 50px !important;
  border-top-left-radius: 50px !important;
}

.pevents-none {
  pointer-events: none !important;
}

.pevents-auto {
  pointer-events: auto !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-sm {
  letter-spacing: 1.4px !important;
}

.ls-button {
  letter-spacing: 1.5px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-4 {
  letter-spacing: 4px !important;
}

.ls-5 {
  letter-spacing: 5px !important;
}

.mx-nsm {
  margin-right: -0.33335rem !important;
  margin-left: -0.33335rem !important;
}

.mx-n3px {
  margin-right: -3px !important;
  margin-left: -3px !important;
}

.mx-n4px {
  margin-right: -4px !important;
  margin-left: -4px !important;
}

.mx-n8px {
  margin-right: -8px !important;
  margin-left: -8px !important;
}

.mx-n12px {
  margin-right: -12px !important;
  margin-left: -12px !important;
}

.mx-n15px {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n16px {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

.mx-n18px {
  margin-right: -18px !important;
  margin-left: -18px !important;
}

.mx-n24px {
  margin-right: -24px !important;
  margin-left: -24px !important;
}

.mx-n25px {
  margin-right: -25px !important;
  margin-left: -25px !important;
}

.mx-n30px {
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.mx-n32px {
  margin-right: -32px !important;
  margin-left: -32px !important;
}

.mx-n57px {
  margin-right: -57px !important;
  margin-left: -57px !important;
}

.mx-nn15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-nn5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mx-n3vh {
  margin-right: -3vh !important;
  margin-left: -3vh !important;
}

.mx-n10vh {
  margin-right: -10vh !important;
  margin-left: -10vh !important;
}

.mx-n1 {
  margin-right: -0.6667rem !important;
  margin-left: -0.6667rem !important;
}

.mx-n2 {
  margin-right: -1.3334rem !important;
  margin-left: -1.3334rem !important;
}

.mx-n3 {
  margin-right: -2.0001rem !important;
  margin-left: -2.0001rem !important;
}

.mx-n4 {
  margin-right: -2.6668rem !important;
  margin-left: -2.6668rem !important;
}

.mx-n5 {
  margin-right: -3.3335rem !important;
  margin-left: -3.3335rem !important;
}

.mx-n6 {
  margin-right: -4.0002rem !important;
  margin-left: -4.0002rem !important;
}

.mx-n7 {
  margin-right: -4.6669rem !important;
  margin-left: -4.6669rem !important;
}

.mx-n8 {
  margin-right: -5.3336rem !important;
  margin-left: -5.3336rem !important;
}

.mx-n9 {
  margin-right: -6.0003rem !important;
  margin-left: -6.0003rem !important;
}

.mx-n10 {
  margin-right: -6.667rem !important;
  margin-left: -6.667rem !important;
}

.mx-ngutter {
  margin-right: -24px !important;
  margin-left: -24px !important;
}

.mx-nout-container {
  margin-right: -calc(50% - 50vw) !important;
  margin-left: -calc(50% - 50vw) !important;
}

.my-nsm {
  margin-top: -0.33335rem !important;
  margin-bottom: -0.33335rem !important;
}

.my-n3px {
  margin-top: -3px !important;
  margin-bottom: -3px !important;
}

.my-n4px {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.my-n8px {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.my-n12px {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.my-n15px {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n16px {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.my-n18px {
  margin-top: -18px !important;
  margin-bottom: -18px !important;
}

.my-n24px {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.my-n25px {
  margin-top: -25px !important;
  margin-bottom: -25px !important;
}

.my-n30px {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.my-n32px {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.my-n57px {
  margin-top: -57px !important;
  margin-bottom: -57px !important;
}

.my-nn15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-nn5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-n3vh {
  margin-top: -3vh !important;
  margin-bottom: -3vh !important;
}

.my-n10vh {
  margin-top: -10vh !important;
  margin-bottom: -10vh !important;
}

.my-n1 {
  margin-top: -0.6667rem !important;
  margin-bottom: -0.6667rem !important;
}

.my-n2 {
  margin-top: -1.3334rem !important;
  margin-bottom: -1.3334rem !important;
}

.my-n3 {
  margin-top: -2.0001rem !important;
  margin-bottom: -2.0001rem !important;
}

.my-n4 {
  margin-top: -2.6668rem !important;
  margin-bottom: -2.6668rem !important;
}

.my-n5 {
  margin-top: -3.3335rem !important;
  margin-bottom: -3.3335rem !important;
}

.my-n6 {
  margin-top: -4.0002rem !important;
  margin-bottom: -4.0002rem !important;
}

.my-n7 {
  margin-top: -4.6669rem !important;
  margin-bottom: -4.6669rem !important;
}

.my-n8 {
  margin-top: -5.3336rem !important;
  margin-bottom: -5.3336rem !important;
}

.my-n9 {
  margin-top: -6.0003rem !important;
  margin-bottom: -6.0003rem !important;
}

.my-n10 {
  margin-top: -6.667rem !important;
  margin-bottom: -6.667rem !important;
}

.my-ngutter {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.my-nout-container {
  margin-top: -calc(50% - 50vw) !important;
  margin-bottom: -calc(50% - 50vw) !important;
}

.mt-nsm {
  margin-top: -0.33335rem !important;
}

.mt-n3px {
  margin-top: -3px !important;
}

.mt-n4px {
  margin-top: -4px !important;
}

.mt-n8px {
  margin-top: -8px !important;
}

.mt-n12px {
  margin-top: -12px !important;
}

.mt-n15px {
  margin-top: -15px !important;
}

.mt-n16px {
  margin-top: -16px !important;
}

.mt-n18px {
  margin-top: -18px !important;
}

.mt-n24px {
  margin-top: -24px !important;
}

.mt-n25px {
  margin-top: -25px !important;
}

.mt-n30px {
  margin-top: -30px !important;
}

.mt-n32px {
  margin-top: -32px !important;
}

.mt-n57px {
  margin-top: -57px !important;
}

.mt-nn15 {
  margin-top: 15px !important;
}

.mt-nn5 {
  margin-top: 5px !important;
}

.mt-n3vh {
  margin-top: -3vh !important;
}

.mt-n10vh {
  margin-top: -10vh !important;
}

.mt-n1 {
  margin-top: -0.6667rem !important;
}

.mt-n2 {
  margin-top: -1.3334rem !important;
}

.mt-n3 {
  margin-top: -2.0001rem !important;
}

.mt-n4 {
  margin-top: -2.6668rem !important;
}

.mt-n5 {
  margin-top: -3.3335rem !important;
}

.mt-n6 {
  margin-top: -4.0002rem !important;
}

.mt-n7 {
  margin-top: -4.6669rem !important;
}

.mt-n8 {
  margin-top: -5.3336rem !important;
}

.mt-n9 {
  margin-top: -6.0003rem !important;
}

.mt-n10 {
  margin-top: -6.667rem !important;
}

.mt-ngutter {
  margin-top: -24px !important;
}

.mt-nout-container {
  margin-top: -calc(50% - 50vw) !important;
}

.me-nsm {
  margin-right: -0.33335rem !important;
}

.me-n3px {
  margin-right: -3px !important;
}

.me-n4px {
  margin-right: -4px !important;
}

.me-n8px {
  margin-right: -8px !important;
}

.me-n12px {
  margin-right: -12px !important;
}

.me-n15px {
  margin-right: -15px !important;
}

.me-n16px {
  margin-right: -16px !important;
}

.me-n18px {
  margin-right: -18px !important;
}

.me-n24px {
  margin-right: -24px !important;
}

.me-n25px {
  margin-right: -25px !important;
}

.me-n30px {
  margin-right: -30px !important;
}

.me-n32px {
  margin-right: -32px !important;
}

.me-n57px {
  margin-right: -57px !important;
}

.me-nn15 {
  margin-right: 15px !important;
}

.me-nn5 {
  margin-right: 5px !important;
}

.me-n3vh {
  margin-right: -3vh !important;
}

.me-n10vh {
  margin-right: -10vh !important;
}

.me-n1 {
  margin-right: -0.6667rem !important;
}

.me-n2 {
  margin-right: -1.3334rem !important;
}

.me-n3 {
  margin-right: -2.0001rem !important;
}

.me-n4 {
  margin-right: -2.6668rem !important;
}

.me-n5 {
  margin-right: -3.3335rem !important;
}

.me-n6 {
  margin-right: -4.0002rem !important;
}

.me-n7 {
  margin-right: -4.6669rem !important;
}

.me-n8 {
  margin-right: -5.3336rem !important;
}

.me-n9 {
  margin-right: -6.0003rem !important;
}

.me-n10 {
  margin-right: -6.667rem !important;
}

.me-ngutter {
  margin-right: -24px !important;
}

.me-nout-container {
  margin-right: -calc(50% - 50vw) !important;
}

.mb-nsm {
  margin-bottom: -0.33335rem !important;
}

.mb-n3px {
  margin-bottom: -3px !important;
}

.mb-n4px {
  margin-bottom: -4px !important;
}

.mb-n8px {
  margin-bottom: -8px !important;
}

.mb-n12px {
  margin-bottom: -12px !important;
}

.mb-n15px {
  margin-bottom: -15px !important;
}

.mb-n16px {
  margin-bottom: -16px !important;
}

.mb-n18px {
  margin-bottom: -18px !important;
}

.mb-n24px {
  margin-bottom: -24px !important;
}

.mb-n25px {
  margin-bottom: -25px !important;
}

.mb-n30px {
  margin-bottom: -30px !important;
}

.mb-n32px {
  margin-bottom: -32px !important;
}

.mb-n57px {
  margin-bottom: -57px !important;
}

.mb-nn15 {
  margin-bottom: 15px !important;
}

.mb-nn5 {
  margin-bottom: 5px !important;
}

.mb-n3vh {
  margin-bottom: -3vh !important;
}

.mb-n10vh {
  margin-bottom: -10vh !important;
}

.mb-n1 {
  margin-bottom: -0.6667rem !important;
}

.mb-n2 {
  margin-bottom: -1.3334rem !important;
}

.mb-n3 {
  margin-bottom: -2.0001rem !important;
}

.mb-n4 {
  margin-bottom: -2.6668rem !important;
}

.mb-n5 {
  margin-bottom: -3.3335rem !important;
}

.mb-n6 {
  margin-bottom: -4.0002rem !important;
}

.mb-n7 {
  margin-bottom: -4.6669rem !important;
}

.mb-n8 {
  margin-bottom: -5.3336rem !important;
}

.mb-n9 {
  margin-bottom: -6.0003rem !important;
}

.mb-n10 {
  margin-bottom: -6.667rem !important;
}

.mb-ngutter {
  margin-bottom: -24px !important;
}

.mb-nout-container {
  margin-bottom: -calc(50% - 50vw) !important;
}

.ms-nsm {
  margin-left: -0.33335rem !important;
}

.ms-n3px {
  margin-left: -3px !important;
}

.ms-n4px {
  margin-left: -4px !important;
}

.ms-n8px {
  margin-left: -8px !important;
}

.ms-n12px {
  margin-left: -12px !important;
}

.ms-n15px {
  margin-left: -15px !important;
}

.ms-n16px {
  margin-left: -16px !important;
}

.ms-n18px {
  margin-left: -18px !important;
}

.ms-n24px {
  margin-left: -24px !important;
}

.ms-n25px {
  margin-left: -25px !important;
}

.ms-n30px {
  margin-left: -30px !important;
}

.ms-n32px {
  margin-left: -32px !important;
}

.ms-n57px {
  margin-left: -57px !important;
}

.ms-nn15 {
  margin-left: 15px !important;
}

.ms-nn5 {
  margin-left: 5px !important;
}

.ms-n3vh {
  margin-left: -3vh !important;
}

.ms-n10vh {
  margin-left: -10vh !important;
}

.ms-n1 {
  margin-left: -0.6667rem !important;
}

.ms-n2 {
  margin-left: -1.3334rem !important;
}

.ms-n3 {
  margin-left: -2.0001rem !important;
}

.ms-n4 {
  margin-left: -2.6668rem !important;
}

.ms-n5 {
  margin-left: -3.3335rem !important;
}

.ms-n6 {
  margin-left: -4.0002rem !important;
}

.ms-n7 {
  margin-left: -4.6669rem !important;
}

.ms-n8 {
  margin-left: -5.3336rem !important;
}

.ms-n9 {
  margin-left: -6.0003rem !important;
}

.ms-n10 {
  margin-left: -6.667rem !important;
}

.ms-ngutter {
  margin-left: -24px !important;
}

.ms-nout-container {
  margin-left: -calc(50% - 50vw) !important;
}

.list-style-none {
  list-style: none !important;
}

.list-style-circle {
  list-style: circle !important;
}

.list-style-disc {
  list-style: disc !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.wb-normal {
  word-break: normal !important;
}

.wb-break-all {
  word-break: break-all !important;
}

.wb-keep-all {
  word-break: keep-all !important;
}

.wb-break-word {
  word-break: break-word !important;
}

.wb-initial {
  word-break: initial !important;
}

.wb-inherit {
  word-break: inherit !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #e3e3e3;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #d0cfcd;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #f6f5f5 #f6f5f5 #d0cfcd;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: #d0cfcd #d0cfcd #ffffff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #0a3255;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.33335rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: -1.28125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 3.375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 3.375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #0a3255;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0a3255;
  --bs-btn-border-color: #0a3255;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #092b48;
  --bs-btn-hover-border-color: #082844;
  --bs-btn-focus-shadow-rgb: 47, 81, 111;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #082844;
  --bs-btn-active-border-color: #082640;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0a3255;
  --bs-btn-disabled-border-color: #0a3255;
}

.btn-primary-light {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: rgba(10, 50, 85, 0.5);
  --bs-btn-border-color: rgba(10, 50, 85, 0.5);
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: rgba(7, 33, 56, 0.575);
  --bs-btn-hover-border-color: rgba(6, 29, 49, 0.6);
  --bs-btn-focus-shadow-rgb: 95, 121, 144;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: rgba(6, 29, 49, 0.6);
  --bs-btn-active-border-color: rgba(5, 25, 43, 0.625);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: rgba(10, 50, 85, 0.5);
  --bs-btn-disabled-border-color: rgba(10, 50, 85, 0.5);
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #da262e;
  --bs-btn-border-color: #da262e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b92027;
  --bs-btn-hover-border-color: #ae1e25;
  --bs-btn-focus-shadow-rgb: 224, 71, 77;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ae1e25;
  --bs-btn-active-border-color: #a41d23;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #da262e;
  --bs-btn-disabled-border-color: #da262e;
}

.btn-secondary-light {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: rgba(218, 38, 46, 0.5);
  --bs-btn-border-color: rgba(218, 38, 46, 0.5);
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: rgba(143, 25, 30, 0.575);
  --bs-btn-hover-border-color: rgba(125, 22, 26, 0.6);
  --bs-btn-focus-shadow-rgb: 231, 113, 118;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: rgba(125, 22, 26, 0.6);
  --bs-btn-active-border-color: rgba(109, 19, 23, 0.625);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: rgba(218, 38, 46, 0.5);
  --bs-btn-disabled-border-color: rgba(218, 38, 46, 0.5);
}

.btn-westar {
  --bs-btn-color: #000;
  --bs-btn-bg: #ddd8ce;
  --bs-btn-border-color: #ddd8ce;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e2ded5;
  --bs-btn-hover-border-color: #e0dcd3;
  --bs-btn-focus-shadow-rgb: 188, 184, 175;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e4e0d8;
  --bs-btn-active-border-color: #e0dcd3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ddd8ce;
  --bs-btn-disabled-border-color: #ddd8ce;
}

.btn-swirl {
  --bs-btn-color: #000;
  --bs-btn-bg: #d7cdca;
  --bs-btn-border-color: #d7cdca;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ddd5d2;
  --bs-btn-hover-border-color: #dbd2cf;
  --bs-btn-focus-shadow-rgb: 183, 174, 172;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dfd7d5;
  --bs-btn-active-border-color: #dbd2cf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d7cdca;
  --bs-btn-disabled-border-color: #d7cdca;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #fbfbfb;
  --bs-btn-border-color: #fbfbfb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d5d5d5;
  --bs-btn-hover-border-color: #c9c9c9;
  --bs-btn-focus-shadow-rgb: 213, 213, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c9c9c9;
  --bs-btn-active-border-color: #bcbcbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fbfbfb;
  --bs-btn-disabled-border-color: #fbfbfb;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #1acf82;
  --bs-btn-border-color: #1acf82;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3cd695;
  --bs-btn-hover-border-color: #31d48f;
  --bs-btn-focus-shadow-rgb: 22, 176, 111;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #48d99b;
  --bs-btn-active-border-color: #31d48f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1acf82;
  --bs-btn-disabled-border-color: #1acf82;
}

.btn-gray-100 {
  --bs-btn-color: #000;
  --bs-btn-bg: #9a9ea6;
  --bs-btn-border-color: #9a9ea6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #a9adb3;
  --bs-btn-hover-border-color: #a4a8af;
  --bs-btn-focus-shadow-rgb: 131, 134, 141;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #aeb1b8;
  --bs-btn-active-border-color: #a4a8af;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #9a9ea6;
  --bs-btn-disabled-border-color: #9a9ea6;
}

.btn-gray-200 {
  --bs-btn-color: #000;
  --bs-btn-bg: #f6f5f5;
  --bs-btn-border-color: #f6f5f5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7f7f7;
  --bs-btn-hover-border-color: #f7f6f6;
  --bs-btn-focus-shadow-rgb: 209, 208, 208;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f7f7;
  --bs-btn-active-border-color: #f7f6f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6f5f5;
  --bs-btn-disabled-border-color: #f6f5f5;
}

.btn-gray-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #d0cfcd;
  --bs-btn-border-color: #d0cfcd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d7d6d5;
  --bs-btn-hover-border-color: #d5d4d2;
  --bs-btn-focus-shadow-rgb: 177, 176, 174;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d9d9d7;
  --bs-btn-active-border-color: #d5d4d2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d0cfcd;
  --bs-btn-disabled-border-color: #d0cfcd;
}

.btn-gray-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #efefef;
  --bs-btn-border-color: #efefef;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1f1f1;
  --bs-btn-hover-border-color: #f1f1f1;
  --bs-btn-focus-shadow-rgb: 203, 203, 203;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2f2f2;
  --bs-btn-active-border-color: #f1f1f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #efefef;
  --bs-btn-disabled-border-color: #efefef;
}

.btn-gray-500 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2a2644;
  --bs-btn-border-color: #2a2644;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #24203a;
  --bs-btn-hover-border-color: #221e36;
  --bs-btn-focus-shadow-rgb: 74, 71, 96;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #221e36;
  --bs-btn-active-border-color: #201d33;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2a2644;
  --bs-btn-disabled-border-color: #2a2644;
}

.btn-gray-600 {
  --bs-btn-color: #000;
  --bs-btn-bg: #e3e3e3;
  --bs-btn-border-color: #e3e3e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e7e7e7;
  --bs-btn-hover-border-color: #e6e6e6;
  --bs-btn-focus-shadow-rgb: 193, 193, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9e9e9;
  --bs-btn-active-border-color: #e6e6e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e3e3e3;
  --bs-btn-disabled-border-color: #e3e3e3;
}

.btn-gray-800 {
  --bs-btn-color: #000;
  --bs-btn-bg: #e0e0e0;
  --bs-btn-border-color: #e0e0e0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e5e5;
  --bs-btn-hover-border-color: #e3e3e3;
  --bs-btn-focus-shadow-rgb: 190, 190, 190;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e6e6e6;
  --bs-btn-active-border-color: #e3e3e3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e0e0e0;
  --bs-btn-disabled-border-color: #e0e0e0;
}

.btn-gray-900 {
  --bs-btn-color: #000;
  --bs-btn-bg: #c4c4c4;
  --bs-btn-border-color: #c4c4c4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cdcdcd;
  --bs-btn-hover-border-color: #cacaca;
  --bs-btn-focus-shadow-rgb: 167, 167, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d0d0d0;
  --bs-btn-active-border-color: #cacaca;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c4c4c4;
  --bs-btn-disabled-border-color: #c4c4c4;
}

.btn-body {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0a3255;
  --bs-btn-border-color: #0a3255;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #092b48;
  --bs-btn-hover-border-color: #082844;
  --bs-btn-focus-shadow-rgb: 47, 81, 111;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #082844;
  --bs-btn-active-border-color: #082640;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0a3255;
  --bs-btn-disabled-border-color: #0a3255;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #60b8d0;
  --bs-btn-border-color: #60b8d0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #78c3d7;
  --bs-btn-hover-border-color: #70bfd5;
  --bs-btn-focus-shadow-rgb: 82, 156, 177;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #80c6d9;
  --bs-btn-active-border-color: #70bfd5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #60b8d0;
  --bs-btn-disabled-border-color: #60b8d0;
}

.btn-info-100 {
  --bs-btn-color: #000;
  --bs-btn-bg: #0083c1;
  --bs-btn-border-color: #0083c1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2696ca;
  --bs-btn-hover-border-color: #1a8fc7;
  --bs-btn-focus-shadow-rgb: 0, 111, 164;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #339ccd;
  --bs-btn-active-border-color: #1a8fc7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0083c1;
  --bs-btn-disabled-border-color: #0083c1;
}

.btn-info-200 {
  --bs-btn-color: #000;
  --bs-btn-bg: #52b1c5;
  --bs-btn-border-color: #52b1c5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6cbdce;
  --bs-btn-hover-border-color: #63b9cb;
  --bs-btn-focus-shadow-rgb: 70, 150, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #75c1d1;
  --bs-btn-active-border-color: #63b9cb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #52b1c5;
  --bs-btn-disabled-border-color: #52b1c5;
}

.btn-info-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #97d1dc;
  --bs-btn-border-color: #97d1dc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #a7d8e1;
  --bs-btn-hover-border-color: #a1d6e0;
  --bs-btn-focus-shadow-rgb: 128, 178, 187;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #acdae3;
  --bs-btn-active-border-color: #a1d6e0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #97d1dc;
  --bs-btn-disabled-border-color: #97d1dc;
}

.btn-info-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #40a7be;
  --bs-btn-border-color: #40a7be;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #5db4c8;
  --bs-btn-hover-border-color: #53b0c5;
  --bs-btn-focus-shadow-rgb: 54, 142, 162;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #66b9cb;
  --bs-btn-active-border-color: #53b0c5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #40a7be;
  --bs-btn-disabled-border-color: #40a7be;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #d3ad57;
  --bs-btn-border-color: #d3ad57;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dab970;
  --bs-btn-hover-border-color: #d7b568;
  --bs-btn-focus-shadow-rgb: 179, 147, 74;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dcbd79;
  --bs-btn-active-border-color: #d7b568;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d3ad57;
  --bs-btn-disabled-border-color: #d3ad57;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #d7282f;
  --bs-btn-border-color: #d7282f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b72228;
  --bs-btn-hover-border-color: #ac2026;
  --bs-btn-focus-shadow-rgb: 221, 72, 78;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ac2026;
  --bs-btn-active-border-color: #a11e23;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #d7282f;
  --bs-btn-disabled-border-color: #d7282f;
}

.btn-dark {
  --bs-btn-color: #000;
  --bs-btn-bg: #e9e9e9;
  --bs-btn-border-color: #e9e9e9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ececec;
  --bs-btn-hover-border-color: #ebebeb;
  --bs-btn-focus-shadow-rgb: 198, 198, 198;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ededed;
  --bs-btn-active-border-color: #ebebeb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e9e9e9;
  --bs-btn-disabled-border-color: #e9e9e9;
}

.btn-black {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000;
}

.btn-red {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #d7282f;
  --bs-btn-border-color: #d7282f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b72228;
  --bs-btn-hover-border-color: #ac2026;
  --bs-btn-focus-shadow-rgb: 221, 72, 78;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ac2026;
  --bs-btn-active-border-color: #a11e23;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #d7282f;
  --bs-btn-disabled-border-color: #d7282f;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-transparent {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: rgba(0, 0, 0, 0.15);
  --bs-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: rgba(0, 0, 0, 0.2);
  --bs-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
}

.btn-outline-primary {
  --bs-btn-color: #0a3255;
  --bs-btn-border-color: #0a3255;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0a3255;
  --bs-btn-hover-border-color: #0a3255;
  --bs-btn-focus-shadow-rgb: 10, 50, 85;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0a3255;
  --bs-btn-active-border-color: #0a3255;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0a3255;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0a3255;
  --bs-gradient: none;
}

.btn-outline-primary-light {
  --bs-btn-color: rgba(10, 50, 85, 0.5);
  --bs-btn-border-color: rgba(10, 50, 85, 0.5);
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: rgba(10, 50, 85, 0.5);
  --bs-btn-hover-border-color: rgba(10, 50, 85, 0.5);
  --bs-btn-focus-shadow-rgb: 10, 50, 85;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: rgba(10, 50, 85, 0.5);
  --bs-btn-active-border-color: rgba(10, 50, 85, 0.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(10, 50, 85, 0.5);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(10, 50, 85, 0.5);
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #da262e;
  --bs-btn-border-color: #da262e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #da262e;
  --bs-btn-hover-border-color: #da262e;
  --bs-btn-focus-shadow-rgb: 218, 38, 46;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #da262e;
  --bs-btn-active-border-color: #da262e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #da262e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #da262e;
  --bs-gradient: none;
}

.btn-outline-secondary-light {
  --bs-btn-color: rgba(218, 38, 46, 0.5);
  --bs-btn-border-color: rgba(218, 38, 46, 0.5);
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: rgba(218, 38, 46, 0.5);
  --bs-btn-hover-border-color: rgba(218, 38, 46, 0.5);
  --bs-btn-focus-shadow-rgb: 218, 38, 46;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: rgba(218, 38, 46, 0.5);
  --bs-btn-active-border-color: rgba(218, 38, 46, 0.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(218, 38, 46, 0.5);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(218, 38, 46, 0.5);
  --bs-gradient: none;
}

.btn-outline-westar {
  --bs-btn-color: #ddd8ce;
  --bs-btn-border-color: #ddd8ce;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ddd8ce;
  --bs-btn-hover-border-color: #ddd8ce;
  --bs-btn-focus-shadow-rgb: 221, 216, 206;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ddd8ce;
  --bs-btn-active-border-color: #ddd8ce;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ddd8ce;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ddd8ce;
  --bs-gradient: none;
}

.btn-outline-swirl {
  --bs-btn-color: #d7cdca;
  --bs-btn-border-color: #d7cdca;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d7cdca;
  --bs-btn-hover-border-color: #d7cdca;
  --bs-btn-focus-shadow-rgb: 215, 205, 202;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d7cdca;
  --bs-btn-active-border-color: #d7cdca;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d7cdca;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d7cdca;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #fbfbfb;
  --bs-btn-border-color: #fbfbfb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fbfbfb;
  --bs-btn-hover-border-color: #fbfbfb;
  --bs-btn-focus-shadow-rgb: 251, 251, 251;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fbfbfb;
  --bs-btn-active-border-color: #fbfbfb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fbfbfb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fbfbfb;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #1acf82;
  --bs-btn-border-color: #1acf82;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1acf82;
  --bs-btn-hover-border-color: #1acf82;
  --bs-btn-focus-shadow-rgb: 26, 207, 130;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1acf82;
  --bs-btn-active-border-color: #1acf82;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1acf82;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1acf82;
  --bs-gradient: none;
}

.btn-outline-gray-100 {
  --bs-btn-color: #9a9ea6;
  --bs-btn-border-color: #9a9ea6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9a9ea6;
  --bs-btn-hover-border-color: #9a9ea6;
  --bs-btn-focus-shadow-rgb: 154, 158, 166;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #9a9ea6;
  --bs-btn-active-border-color: #9a9ea6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9a9ea6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9a9ea6;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #f6f5f5;
  --bs-btn-border-color: #f6f5f5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6f5f5;
  --bs-btn-hover-border-color: #f6f5f5;
  --bs-btn-focus-shadow-rgb: 246, 245, 245;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6f5f5;
  --bs-btn-active-border-color: #f6f5f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f6f5f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6f5f5;
  --bs-gradient: none;
}

.btn-outline-gray-300 {
  --bs-btn-color: #d0cfcd;
  --bs-btn-border-color: #d0cfcd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d0cfcd;
  --bs-btn-hover-border-color: #d0cfcd;
  --bs-btn-focus-shadow-rgb: 208, 207, 205;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d0cfcd;
  --bs-btn-active-border-color: #d0cfcd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d0cfcd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d0cfcd;
  --bs-gradient: none;
}

.btn-outline-gray-400 {
  --bs-btn-color: #efefef;
  --bs-btn-border-color: #efefef;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #efefef;
  --bs-btn-hover-border-color: #efefef;
  --bs-btn-focus-shadow-rgb: 239, 239, 239;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #efefef;
  --bs-btn-active-border-color: #efefef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #efefef;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #efefef;
  --bs-gradient: none;
}

.btn-outline-gray-500 {
  --bs-btn-color: #2a2644;
  --bs-btn-border-color: #2a2644;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2a2644;
  --bs-btn-hover-border-color: #2a2644;
  --bs-btn-focus-shadow-rgb: 42, 38, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2a2644;
  --bs-btn-active-border-color: #2a2644;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2a2644;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2a2644;
  --bs-gradient: none;
}

.btn-outline-gray-600 {
  --bs-btn-color: #e3e3e3;
  --bs-btn-border-color: #e3e3e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e3e3e3;
  --bs-btn-hover-border-color: #e3e3e3;
  --bs-btn-focus-shadow-rgb: 227, 227, 227;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e3e3e3;
  --bs-btn-active-border-color: #e3e3e3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e3e3e3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e3e3e3;
  --bs-gradient: none;
}

.btn-outline-gray-800 {
  --bs-btn-color: #e0e0e0;
  --bs-btn-border-color: #e0e0e0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0e0e0;
  --bs-btn-hover-border-color: #e0e0e0;
  --bs-btn-focus-shadow-rgb: 224, 224, 224;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e0e0e0;
  --bs-btn-active-border-color: #e0e0e0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e0e0e0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e0e0e0;
  --bs-gradient: none;
}

.btn-outline-gray-900 {
  --bs-btn-color: #c4c4c4;
  --bs-btn-border-color: #c4c4c4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c4c4c4;
  --bs-btn-hover-border-color: #c4c4c4;
  --bs-btn-focus-shadow-rgb: 196, 196, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c4c4c4;
  --bs-btn-active-border-color: #c4c4c4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c4c4c4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c4c4c4;
  --bs-gradient: none;
}

.btn-outline-body {
  --bs-btn-color: #0a3255;
  --bs-btn-border-color: #0a3255;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0a3255;
  --bs-btn-hover-border-color: #0a3255;
  --bs-btn-focus-shadow-rgb: 10, 50, 85;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0a3255;
  --bs-btn-active-border-color: #0a3255;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0a3255;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0a3255;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #60b8d0;
  --bs-btn-border-color: #60b8d0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #60b8d0;
  --bs-btn-hover-border-color: #60b8d0;
  --bs-btn-focus-shadow-rgb: 96, 184, 208;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #60b8d0;
  --bs-btn-active-border-color: #60b8d0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #60b8d0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #60b8d0;
  --bs-gradient: none;
}

.btn-outline-info-100 {
  --bs-btn-color: #0083c1;
  --bs-btn-border-color: #0083c1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0083c1;
  --bs-btn-hover-border-color: #0083c1;
  --bs-btn-focus-shadow-rgb: 0, 131, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0083c1;
  --bs-btn-active-border-color: #0083c1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0083c1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0083c1;
  --bs-gradient: none;
}

.btn-outline-info-200 {
  --bs-btn-color: #52b1c5;
  --bs-btn-border-color: #52b1c5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #52b1c5;
  --bs-btn-hover-border-color: #52b1c5;
  --bs-btn-focus-shadow-rgb: 82, 177, 197;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #52b1c5;
  --bs-btn-active-border-color: #52b1c5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #52b1c5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #52b1c5;
  --bs-gradient: none;
}

.btn-outline-info-300 {
  --bs-btn-color: #97d1dc;
  --bs-btn-border-color: #97d1dc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #97d1dc;
  --bs-btn-hover-border-color: #97d1dc;
  --bs-btn-focus-shadow-rgb: 151, 209, 220;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #97d1dc;
  --bs-btn-active-border-color: #97d1dc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #97d1dc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #97d1dc;
  --bs-gradient: none;
}

.btn-outline-info-400 {
  --bs-btn-color: #40a7be;
  --bs-btn-border-color: #40a7be;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #40a7be;
  --bs-btn-hover-border-color: #40a7be;
  --bs-btn-focus-shadow-rgb: 64, 167, 190;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #40a7be;
  --bs-btn-active-border-color: #40a7be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #40a7be;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #40a7be;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #d3ad57;
  --bs-btn-border-color: #d3ad57;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3ad57;
  --bs-btn-hover-border-color: #d3ad57;
  --bs-btn-focus-shadow-rgb: 211, 173, 87;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d3ad57;
  --bs-btn-active-border-color: #d3ad57;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d3ad57;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d3ad57;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #d7282f;
  --bs-btn-border-color: #d7282f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d7282f;
  --bs-btn-hover-border-color: #d7282f;
  --bs-btn-focus-shadow-rgb: 215, 40, 47;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d7282f;
  --bs-btn-active-border-color: #d7282f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d7282f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d7282f;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #e9e9e9;
  --bs-btn-border-color: #e9e9e9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e9e9e9;
  --bs-btn-hover-border-color: #e9e9e9;
  --bs-btn-focus-shadow-rgb: 233, 233, 233;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9e9e9;
  --bs-btn-active-border-color: #e9e9e9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e9e9e9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e9e9e9;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #d7282f;
  --bs-btn-border-color: #d7282f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d7282f;
  --bs-btn-hover-border-color: #d7282f;
  --bs-btn-focus-shadow-rgb: 215, 40, 47;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d7282f;
  --bs-btn-active-border-color: #d7282f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d7282f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d7282f;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-transparent {
  --bs-btn-color: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: transparent;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #e3e3e3;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 47, 81, 111;
  text-decoration: underline;
}
.btn-link:focus {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 3.375rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 0.25rem;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 0.6667rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 0.6667rem;
  --bs-modal-header-padding-y: 0.6667rem;
  --bs-modal-header-padding: 0.6667rem 0.6667rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 768.98px) {
  .modal-fullscreen-mdlg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-mdlg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-mdlg-down .modal-header,
.modal-fullscreen-mdlg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-mdlg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #0a3255;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a3255'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #0a3255;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 3.375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e3e3e3;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a3255;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #efefef;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #0a3255;
  background-color: #ffffff;
  border-color: #8599aa;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #e3e3e3;
  opacity: 1;
}
.form-control::placeholder {
  color: #e3e3e3;
  opacity: 1;
}
.form-control:disabled {
  background-color: #f6f5f5;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #0a3255;
  background-color: #f6f5f5;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #eae9e9;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #0a3255;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 3.375rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a3255;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e0e0e0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #efefef;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8599aa;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f6f5f5;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #0a3255;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 3.375rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8599aa;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
}
.form-check-input:checked {
  background-color: #60b8d0;
  border-color: #60b8d0;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0a3255;
  border-color: #0a3255;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238599aa'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0a3255;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6c2cc;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d0cfcd;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0a3255;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6c2cc;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d0cfcd;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #2a2644;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #2a2644;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a3255;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f5f5;
  border: 1px solid #efefef;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 3.375rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #1acf82;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 10px 20px;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #000;
  background-color: #1acf82;
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #1acf82;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231acf82' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #1acf82;
  box-shadow: 0 0 0 0.25rem rgba(26, 207, 130, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #1acf82;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e0e0e0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231acf82' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #1acf82;
  box-shadow: 0 0 0 0.25rem rgba(26, 207, 130, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #1acf82;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #1acf82;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 207, 130, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #1acf82;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d7282f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 10px 20px;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #ffffff;
  background-color: #d7282f;
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d7282f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d7282f'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d7282f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d7282f;
  box-shadow: 0 0 0 0.25rem rgba(215, 40, 47, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #d7282f;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e0e0e0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d7282f'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d7282f' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #d7282f;
  box-shadow: 0 0 0 0.25rem rgba(215, 40, 47, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #d7282f;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #d7282f;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(215, 40, 47, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d7282f;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 0.6667rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ced6dd;
  --bs-table-border-color: #b9c1c7;
  --bs-table-striped-bg: #c4cbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9c1c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfc6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #f8d4d5;
  --bs-table-border-color: #dfbfc0;
  --bs-table-striped-bg: #ecc9ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfbfc0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c4c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1f5e6;
  --bs-table-border-color: #bcddcf;
  --bs-table-striped-bg: #c7e9db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcddcf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1e3d5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #dff1f6;
  --bs-table-border-color: #c9d9dd;
  --bs-table-striped-bg: #d4e5ea;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9d9dd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cedfe4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #f6efdd;
  --bs-table-border-color: #ddd7c7;
  --bs-table-striped-bg: #eae3d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ddd7c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4ddcc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f7d4d5;
  --bs-table-border-color: #debfc0;
  --bs-table-striped-bg: #ebc9ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #debfc0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4c4c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #fbfbfb;
  --bs-table-border-color: #e2e2e2;
  --bs-table-striped-bg: #eeeeee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2e2e2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8e8e8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #000;
  --bs-table-bg: #e9e9e9;
  --bs-table-border-color: #d2d2d2;
  --bs-table-striped-bg: #dddddd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d2d2d2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d8d8d8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 768.98px) {
  .table-responsive-mdlg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.list-group {
  --bs-list-group-color: #c4c4c4;
  --bs-list-group-bg: #ffffff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 0.6667rem;
  --bs-list-group-item-padding-y: 0.33335rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #9a9ea6;
  --bs-list-group-action-active-color: #0a3255;
  --bs-list-group-action-active-bg: #f6f5f5;
  --bs-list-group-disabled-color: #e3e3e3;
  --bs-list-group-disabled-bg: #ffffff;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #0a3255;
  --bs-list-group-active-border-color: #0a3255;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #061e33;
  background-color: #ced6dd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #061e33;
  background-color: #b9c1c7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #061e33;
  border-color: #061e33;
}

.list-group-item-primary-light {
  color: rgba(3, 17, 28, 0.7);
  background-color: rgba(236, 239, 242, 0.9);
}
.list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
  color: rgba(3, 17, 28, 0.7);
  background-color: rgba(208, 210, 213, 0.91);
}
.list-group-item-primary-light.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(3, 17, 28, 0.7);
  border-color: rgba(3, 17, 28, 0.7);
}

.list-group-item-secondary {
  color: #83171c;
  background-color: #f8d4d5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #83171c;
  background-color: #dfbfc0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #83171c;
  border-color: #83171c;
}

.list-group-item-secondary-light {
  color: rgba(73, 13, 15, 0.7);
  background-color: rgba(252, 238, 239, 0.9);
}
.list-group-item-secondary-light.list-group-item-action:hover, .list-group-item-secondary-light.list-group-item-action:focus {
  color: rgba(73, 13, 15, 0.7);
  background-color: rgba(222, 210, 210, 0.91);
}
.list-group-item-secondary-light.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(73, 13, 15, 0.7);
  border-color: rgba(73, 13, 15, 0.7);
}

.list-group-item-westar {
  color: #585652;
  background-color: #f8f7f5;
}
.list-group-item-westar.list-group-item-action:hover, .list-group-item-westar.list-group-item-action:focus {
  color: #585652;
  background-color: #dfdedd;
}
.list-group-item-westar.list-group-item-action.active {
  color: #ffffff;
  background-color: #585652;
  border-color: #585652;
}

.list-group-item-swirl {
  color: #565251;
  background-color: #f7f5f4;
}
.list-group-item-swirl.list-group-item-action:hover, .list-group-item-swirl.list-group-item-action:focus {
  color: #565251;
  background-color: #dedddc;
}
.list-group-item-swirl.list-group-item-action.active {
  color: #ffffff;
  background-color: #565251;
  border-color: #565251;
}

.list-group-item-light {
  color: #646464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #646464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #646464;
  border-color: #646464;
}

.list-group-item-success {
  color: #0a5334;
  background-color: #d1f5e6;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0a5334;
  background-color: #bcddcf;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #0a5334;
  border-color: #0a5334;
}

.list-group-item-gray-100 {
  color: #5c5f64;
  background-color: #ebeced;
}
.list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
  color: #5c5f64;
  background-color: #d4d4d5;
}
.list-group-item-gray-100.list-group-item-action.active {
  color: #ffffff;
  background-color: #5c5f64;
  border-color: #5c5f64;
}

.list-group-item-gray-200 {
  color: #626262;
  background-color: #fdfdfd;
}
.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
  color: #626262;
  background-color: #e4e4e4;
}
.list-group-item-gray-200.list-group-item-action.active {
  color: #ffffff;
  background-color: #626262;
  border-color: #626262;
}

.list-group-item-gray-300 {
  color: #535352;
  background-color: #f6f5f5;
}
.list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
  color: #535352;
  background-color: #dddddd;
}
.list-group-item-gray-300.list-group-item-action.active {
  color: #ffffff;
  background-color: #535352;
  border-color: #535352;
}

.list-group-item-gray-400 {
  color: #606060;
  background-color: #fcfcfc;
}
.list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
  color: #606060;
  background-color: #e3e3e3;
}
.list-group-item-gray-400.list-group-item-action.active {
  color: #ffffff;
  background-color: #606060;
  border-color: #606060;
}

.list-group-item-gray-500 {
  color: #191729;
  background-color: #d4d4da;
}
.list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
  color: #191729;
  background-color: #bfbfc4;
}
.list-group-item-gray-500.list-group-item-action.active {
  color: #ffffff;
  background-color: #191729;
  border-color: #191729;
}

.list-group-item-gray-600 {
  color: #5b5b5b;
  background-color: #f9f9f9;
}
.list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
  color: #5b5b5b;
  background-color: #e0e0e0;
}
.list-group-item-gray-600.list-group-item-action.active {
  color: #ffffff;
  background-color: #5b5b5b;
  border-color: #5b5b5b;
}

.list-group-item-gray-800 {
  color: #5a5a5a;
  background-color: #f9f9f9;
}
.list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
  color: #5a5a5a;
  background-color: #e0e0e0;
}
.list-group-item-gray-800.list-group-item-action.active {
  color: #ffffff;
  background-color: #5a5a5a;
  border-color: #5a5a5a;
}

.list-group-item-gray-900 {
  color: #4e4e4e;
  background-color: #f3f3f3;
}
.list-group-item-gray-900.list-group-item-action:hover, .list-group-item-gray-900.list-group-item-action:focus {
  color: #4e4e4e;
  background-color: #dbdbdb;
}
.list-group-item-gray-900.list-group-item-action.active {
  color: #ffffff;
  background-color: #4e4e4e;
  border-color: #4e4e4e;
}

.list-group-item-body {
  color: #061e33;
  background-color: #ced6dd;
}
.list-group-item-body.list-group-item-action:hover, .list-group-item-body.list-group-item-action:focus {
  color: #061e33;
  background-color: #b9c1c7;
}
.list-group-item-body.list-group-item-action.active {
  color: #ffffff;
  background-color: #061e33;
  border-color: #061e33;
}

.list-group-item-info {
  color: #3a6e7d;
  background-color: #dff1f6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #3a6e7d;
  background-color: #c9d9dd;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #3a6e7d;
  border-color: #3a6e7d;
}

.list-group-item-info-100 {
  color: #004f74;
  background-color: #cce6f3;
}
.list-group-item-info-100.list-group-item-action:hover, .list-group-item-info-100.list-group-item-action:focus {
  color: #004f74;
  background-color: #b8cfdb;
}
.list-group-item-info-100.list-group-item-action.active {
  color: #ffffff;
  background-color: #004f74;
  border-color: #004f74;
}

.list-group-item-info-200 {
  color: #316a76;
  background-color: #dceff3;
}
.list-group-item-info-200.list-group-item-action:hover, .list-group-item-info-200.list-group-item-action:focus {
  color: #316a76;
  background-color: #c6d7db;
}
.list-group-item-info-200.list-group-item-action.active {
  color: #ffffff;
  background-color: #316a76;
  border-color: #316a76;
}

.list-group-item-info-300 {
  color: #3c5458;
  background-color: #eaf6f8;
}
.list-group-item-info-300.list-group-item-action:hover, .list-group-item-info-300.list-group-item-action:focus {
  color: #3c5458;
  background-color: #d3dddf;
}
.list-group-item-info-300.list-group-item-action.active {
  color: #ffffff;
  background-color: #3c5458;
  border-color: #3c5458;
}

.list-group-item-info-400 {
  color: #266472;
  background-color: #d9edf2;
}
.list-group-item-info-400.list-group-item-action:hover, .list-group-item-info-400.list-group-item-action:focus {
  color: #266472;
  background-color: #c3d5da;
}
.list-group-item-info-400.list-group-item-action.active {
  color: #ffffff;
  background-color: #266472;
  border-color: #266472;
}

.list-group-item-warning {
  color: #7f6834;
  background-color: #f6efdd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7f6834;
  background-color: #ddd7c7;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f6834;
  border-color: #7f6834;
}

.list-group-item-danger {
  color: #81181c;
  background-color: #f7d4d5;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #81181c;
  background-color: #debfc0;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #81181c;
  border-color: #81181c;
}

.list-group-item-dark {
  color: #5d5d5d;
  background-color: #fbfbfb;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #5d5d5d;
  background-color: #e2e2e2;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #5d5d5d;
  border-color: #5d5d5d;
}

.list-group-item-black {
  color: black;
  background-color: #cccccc;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-red {
  color: #81181c;
  background-color: #f7d4d5;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #81181c;
  background-color: #debfc0;
}
.list-group-item-red.list-group-item-action.active {
  color: #ffffff;
  background-color: #81181c;
  border-color: #81181c;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-transparent {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.8);
}
.list-group-item-transparent.list-group-item-action:hover, .list-group-item-transparent.list-group-item-action:focus {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(219, 219, 219, 0.82);
}
.list-group-item-transparent.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23092d4d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8599aa;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #092d4d;
  --bs-accordion-active-bg: #e7ebee;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-mdlg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 0.6667rem;
  --bs-offcanvas-padding-y: 0.6667rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #ffffff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (max-width: 768.98px) {
  .offcanvas-mdlg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 768.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-mdlg {
    transition: none;
  }
}
@media (max-width: 768.98px) {
  .offcanvas-mdlg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 768.98px) {
  .offcanvas-mdlg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 768.98px) {
  .offcanvas-mdlg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 768.98px) {
  .offcanvas-mdlg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 768.98px) {
  .offcanvas-mdlg.showing, .offcanvas-mdlg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 768.98px) {
  .offcanvas-mdlg.showing, .offcanvas-mdlg.hiding, .offcanvas-mdlg.show {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #0a3255;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a3255'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #0a3255;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 50, 85, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 20px;
  --bs-tooltip-padding-y: 10px;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.75rem;
  --bs-tooltip-color: #da262e;
  --bs-tooltip-bg: #ffffff;
  --bs-tooltip-border-radius: 0;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 30px;
  --bs-tooltip-arrow-height: 15px;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "arial-regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #0a3255;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.33335rem;
  --bs-dropdown-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  --bs-dropdown-link-color: #c4c4c4;
  --bs-dropdown-link-hover-color: #b0b0b0;
  --bs-dropdown-link-hover-bg: #f6f5f5;
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #0a3255;
  --bs-dropdown-link-disabled-color: #2a2644;
  --bs-dropdown-item-padding-x: 0.6667rem;
  --bs-dropdown-item-padding-y: 0.166675rem;
  --bs-dropdown-header-color: #e3e3e3;
  --bs-dropdown-header-padding-x: 0.6667rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.75rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #d0cfcd;
  --bs-dropdown-bg: #e0e0e0;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #d0cfcd;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #0a3255;
  --bs-dropdown-link-disabled-color: #2a2644;
  --bs-dropdown-header-color: #2a2644;
}

body.admin-bar header {
  top: 46px !important;
}
body.admin-bar #wpadminbar {
  position: fixed;
  top: 0;
}

header .header-wrapper input#search {
  -webkit-appearance: none;
}
header .header-wrapper ul.menu-wrap {
  padding: 0;
  list-style: none;
}
header .header-wrapper ul.menu-wrap > li.wpml-ls-item a {
  color: rgba(10, 50, 85, 0.5);
}
header .header-wrapper ul.menu-wrap > li.wpml-ls-item.wpml-ls-current-language a {
  color: #0a3255;
}
header .header-wrapper ul.menu-wrap > li > a {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  text-align: center;
  padding: 8px 0;
  margin-right: 16px;
  text-transform: uppercase;
}
header .bar-wrapper {
  width: 30px;
  height: 20px;
  position: relative;
  outline: 0;
}
header .bar-wrapper span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #0a3255;
  opacity: 1;
  right: 0;
  transition: all ease 0.25s;
}
header .bar-wrapper span:nth-child(1) {
  top: 0px;
}
header .bar-wrapper span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
header .bar-wrapper span:nth-child(3) {
  top: auto;
  bottom: 0;
}
header .bar-wrapper.active span:nth-child(1) {
  top: 9px;
  transform: rotate(135deg);
}
header .bar-wrapper.active span:nth-child(2) {
  opacity: 0;
}
header .bar-wrapper.active span:nth-child(3) {
  bottom: 9px;
  transform: rotate(-135deg);
}
header .search-toggle {
  width: 35px;
  height: 35px;
}
header .search-toggle > svg {
  width: 20px;
  height: 20px;
}
header .search-button {
  padding: 20px 5px;
}
header .cart-button {
  padding: 16px 0;
}
header .cart-button .cart-wrapper {
  width: 38px;
  height: 38px;
  position: relative;
}
header .cart-button .cart-wrapper .iconify {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}
header .cart-button .cart-count {
  position: absolute;
  top: 22px;
  left: 34px;
  transform: translate(-50%, -50%);
  font-size: 11px;
  font-weight: bold;
  background: #0a3255;
  color: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
header .dropdown-center .btn:hover, header .dropdown-center .btn.show {
  border-color: transparent;
}
header .dropdown-center .dropdown-menu {
  min-width: 3rem;
}
header .dropdown-center .dropdown-menu .current-language {
  white-space: nowrap;
}
header .offcanvas-backdrop {
  top: 55px;
}
body.admin-bar header .offcanvas-backdrop {
  top: 100px;
}

.collapse-search {
  top: 54px;
  width: 100vw;
  z-index: 1046;
}
.collapse-search .backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 54px);
  background-color: rgba(23, 23, 23, 0.8);
  transition: 0.5s all ease-in-out;
}
.collapse-search .search-wrapper {
  background-color: #ffffff;
  z-index: 1;
}
.collapse-search form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapse-search input {
  height: 60px;
  width: 289px;
}
.collapse-search input::-moz-placeholder {
  color: #0a3255;
}
.collapse-search input::placeholder {
  color: #0a3255;
}
.collapse-search .search-submit {
  height: 60px;
  width: 60px;
  background: url("root/images/arrow_right.png") center center/10px auto no-repeat;
  border: 0;
  cursor: pointer;
}

#offcanvasMenu {
  top: 55px;
  height: calc(100vh - 72px);
  width: 100vw;
}
body.admin-bar #offcanvasMenu {
  top: 100px;
}
#offcanvasMenu .offcanvas-header {
  padding: 24px 30px 12.5px 25px;
}
#offcanvasMenu .offcanvas-body {
  padding: 12.5px 16px;
}
#offcanvasMenu .offcanvas-body .login-wrap {
  margin: 0 -16px;
}
#offcanvasMenu .offcanvas-body ul {
  padding: 0;
}
#offcanvasMenu .offcanvas-body .language-switcher {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: #0a3255;
  padding: 22px 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-transform: uppercase;
  margin: 0 auto;
}
#offcanvasMenu .btn-close {
  background-position: 70px 7px;
  padding-right: 70px;
  opacity: 1;
}
#offcanvasMenu ul.menu-wrap > li {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0;
  position: relative;
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #eff4fa;
}
#offcanvasMenu ul.menu-wrap > li > a {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: #0a3255;
  padding: 22px 0;
  max-width: 160px;
  text-transform: uppercase;
}
#offcanvasMenu ul.menu-wrap > li.wpml-ls-item a {
  color: rgba(10, 50, 85, 0.5);
}
#offcanvasMenu ul.menu-wrap > li.wpml-ls-item.wpml-ls-current-language a {
  color: #0a3255;
}
#offcanvasMenu ul.menu-wrap > li .nav-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
#offcanvasMenu ul.menu-wrap > li .sub-menu {
  position: fixed;
  top: 70px;
  width: 100%;
  right: -100vw;
  left: auto;
  height: calc(100% - 87px);
  background-color: #ffffff;
  width: 100%;
  padding: 0 45px;
  transition: all 0.25s ease;
  z-index: 1046;
  opacity: 0;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li {
  text-align: center;
  padding-top: 0;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li.back-btn a {
  font-size: 0.75rem;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li.menu-item {
  position: relative;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li.menu-item::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: url("root/images/arrow_right.png") no-repeat;
  width: 10px;
  height: 10px;
  background-size: contain;
  transition: all 0.5s ease;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li:first-child {
  padding-top: 16px;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li a {
  font-family: "arial-bold", sans-serif;
  color: #0a3255;
  display: flex;
  flex-direction: row-reverse;
  font-size: 0.75rem;
  justify-content: flex-end;
  align-items: center;
  padding: 19.5px 10px 19.5px 0;
  text-align: left;
}
#offcanvasMenu ul.menu-wrap > li .sub-menu li a img {
  margin-right: 20px;
  height: 45px;
  width: 45px;
}
#offcanvasMenu #menu-main-menu-right > li {
  background-color: #1acf82;
}
#offcanvasMenu .user-wrapper {
  width: 60px;
  height: 60px;
}

body.menu-open header .search-toggle {
  display: none !important;
}
body.menu-open header .bar-wrapper span:first-child {
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
body.menu-open header .bar-wrapper span:last-child {
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
}

.search-wrapper input[type=search] {
  padding: 6px 38px 6px 22px;
  background-color: rgba(208, 207, 205, 0.4);
}
.search-wrapper input[type=submit] {
  width: 40px;
  height: 40px;
  background: 0 0;
  border: none;
  font-size: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.search-wrapper .search-icon {
  right: 10px;
}
.search-wrapper .fibo-search input[type=search].dgwt-wcas-search-input {
  padding: 6px 16px;
  border-radius: 12px;
  height: auto;
  background: #efefef;
  border: 0;
}
.search-wrapper .fibo-search input[type=search].dgwt-wcas-search-input::-moz-placeholder {
  color: #c4c4c4;
  opacity: 0.55;
}
.search-wrapper .fibo-search input[type=search].dgwt-wcas-search-input::placeholder {
  color: #c4c4c4;
  opacity: 0.55;
}
.search-wrapper .fibo-search .dgwt-wcas-no-submit .dgwt-wcas-ico-magnifier {
  right: 20px;
  left: auto;
  opacity: 1;
}
.search-wrapper .fibo-search .dgwt-wcas-preloader {
  right: 35px;
}

.loading-first {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 99999;
}
body.home .loading-first {
  display: none !important;
}
.loading-first.hided {
  display: none !important;
}

.loading-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 99999;
}
.loading-component.hided {
  display: none !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #0a3255;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
  background: rgba(10, 50, 85, 0.5);
}

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
  background: rgba(10, 50, 85, 0.5);
}

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
  background: rgba(10, 50, 85, 0.5);
}

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
  background: linear-gradient(to right, #0a3255, rgba(10, 50, 85, 0.5));
}

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footer {
  position: relative;
}
.footer p {
  line-height: 29px;
}
.footer .wp-block-columns {
  gap: 0;
}
.footer .footer-email {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.footer .footer-social .wp-block-group__inner-container {
  display: flex;
  align-items: center;
}
.footer .footer-terms p {
  margin-bottom: 0;
}
.footer .wpcf7 label {
  font-weight: 700;
}
.footer .wpcf7 input::-moz-placeholder, .footer .wpcf7 textarea::-moz-placeholder {
  color: #999999;
  font-family: "arial-regular", sans-serif;
  font-size: 1rem;
}
.footer .wpcf7 input::placeholder,
.footer .wpcf7 textarea::placeholder {
  color: #999999;
  font-family: "arial-regular", sans-serif;
  font-size: 1rem;
}

.cta-contact-us {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.cta-contact-us h5 {
  font-size: 40px;
}
.cta-contact-us p {
  font-size: 18px;
}
.cta-contact-us .wp-block-button {
  width: 230px;
}

.contact-us .contact-us-background {
  z-index: -1;
}
.contact-us .contact-us-background img {
  height: 100%;
  width: 100%;
}
.contact-us .contact-us-content p {
  color: #ffffff;
  font-size: 18px;
}
.contact-us .contact-us-content p:first-child {
  font-size: 40px;
  font-family: "arial-bold", sans-serif;
  font-weight: bold;
}
.contact-us .contact-us-content p:last-child {
  margin-bottom: 0;
}

.footer-newsletter .row > * {
  padding: 0;
  height: 56px;
}
.footer-newsletter .row > * button,
.footer-newsletter .row > * input {
  height: 100%;
}
.footer-newsletter .row > * button {
  margin-left: -2px;
}
.footer-newsletter .wpcf7 a {
  text-decoration: underline;
  font-size: 16px;
  color: #0a3255;
}
.footer-newsletter .wpcf7-list-item {
  margin: 0;
}
.footer-newsletter .wpcf7-list-item label {
  font-weight: 400;
  font-size: 16px;
  display: flex;
}
.footer-newsletter .row {
  max-width: auto;
}
.footer-newsletter h5 {
  font-size: 32px;
}
.footer-newsletter p {
  font-size: 18px;
  margin-bottom: 0;
  height: 100%;
}

.footer-menu {
  border-top: 1px solid #d0cfcd;
  border-bottom: 1px solid #d0cfcd;
}
.footer-menu a {
  font-size: 18px;
  color: #0a3255;
}
.footer-menu ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.footer-menu li.current_page_item a,
.footer-menu li:hover a {
  color: #d7282f;
}
.footer-menu .col-5 a {
  font-family: "arial-bold", sans-serif;
  font-weight: 700;
  font-size: 20px;
}
.footer-menu .widget-content p {
  font-size: 20px;
}
.footer-menu .wp-block-column {
  flex: 0 0 auto !important;
  max-width: auto !important;
  padding: 0 8px !important;
  flex-basis: auto !important;
}
.footer-menu .footer-menu-2 li:first-child, .footer-menu .footer-menu-1 li:first-child {
  margin-bottom: 10px;
}
.footer-menu .footer-menu-2 li:first-child a, .footer-menu .footer-menu-1 li:first-child a {
  font-family: "arial-bold", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .footer .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: auto !important;
  }
  .footer-newsletter .row {
    max-width: 500px;
  }
}
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2022 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
@font-face {
  font-family: "arial-italic";
  src: url("root/fonts/Arial-ItalicMT.woff2") format("woff2"), url("root/fonts/Arial-ItalicMT.woff") format("woff"), url("root/fonts/Arial-Italic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "arial-bold";
  src: url("root/fonts/Arial-BoldMT.woff2") format("woff2"), url("root/fonts/Arial-BoldMT.woff") format("woff"), url("root/fonts/Arial-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "arial-regular";
  src: url("root/fonts/ArialMT.woff2") format("woff2"), url("root/fonts/ArialMT.woff") format("woff"), url("root/fonts/Arial.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "arial-bolditalic";
  src: url("root/fonts/Arial-BoldItalicMT.woff2") format("woff2"), url("root/fonts/Arial-BoldItalicMT.woff") format("woff"), url("root/fonts/Arial-Bold-Italic.ttf") format("ttf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  position: relative;
}

html {
  font-size: 16px;
}

body {
  min-height: 100%;
  position: relative;
  padding-top: 55px;
  overflow-x: hidden;
  font-family: "arial-regular", sans-serif;
  background-color: #ffffff;
  position: relative;
  --wp--style--block-gap: 30px;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #000;
}
a:hover, a:focus {
  color: #0a3255;
}

figcaption {
  text-align: center;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "arial-bold", sans-serif;
  font-weight: 700;
}
h6 a,
.h6 a,
h5 a,
.h5 a,
h4 a,
.h4 a,
h3 a,
.h3 a,
h2 a,
.h2 a,
h1 a,
.h1 a {
  color: #0a3255;
  transition: 0.3s;
}
h6 a:hover,
.h6 a:hover,
h5 a:hover,
.h5 a:hover,
h4 a:hover,
.h4 a:hover,
h3 a:hover,
.h3 a:hover,
h2 a:hover,
.h2 a:hover,
h1 a:hover,
.h1 a:hover {
  color: #0a3255;
}

strong {
  font-family: "arial-bold", sans-serif;
  font-weight: 700;
}

video:focus {
  outline: none;
}

.pager-list .st-pagelink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pager-list .st-pagelink span,
.pager-list .st-pagelink a {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #0a3255;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pager-list .st-pagelink span {
  background: #0a3255;
  color: #ffffff;
}
.pager-list .st-pagelink a {
  color: #0a3255;
}
.pager-list .st-pagelink img {
  width: 20px;
}

.btn-secondary {
  padding: 12px 20px 9px;
  transition: 0.3s;
  font-family: var(--bs-font-sans-serif-bold);
  color: #ffffff;
  border: 1px solid #da262e;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  background-color: #da262e;
  font-weight: 700;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background-color: #d7282f;
  color: #ffffff;
}

.btn-primary {
  padding: 12px 20px 9px;
  transition: 0.3s;
  font-family: var(--bs-font-sans-serif-bold);
  color: #ffffff;
  border: 1px solid #0a3255;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  background-color: #0a3255;
  font-weight: 700;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #d7282f;
  color: #ffffff;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1acf82;
  --bs-btn-border-color: #1acf82;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #14a266;
  --bs-btn-hover-border-color: #36e69b;
  --bs-btn-focus-shadow-rgb: 60, 214, 149;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #15a668;
  --bs-btn-active-border-color: #149b62;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1acf82;
  --bs-btn-disabled-border-color: #1acf82;
  font-weight: 700;
}

.btn-white {
  padding: 12px 20px 9px;
  transition: 0.3s;
  font-family: var(--bs-font-sans-serif-bold);
  color: #ffffff;
  border: 1px solid #ffffff;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  color: #da262e;
  font-weight: 700;
}
.btn-white:hover, .btn-white:focus, .btn-white:active {
  background-color: #d7282f;
  color: #ffffff;
}
.btn-white:hover, .btn-white:focus, .btn-white:active {
  background-color: #da262e !important;
  border-color: #da262e;
  color: #ffffff !important;
}

button:focus {
  outline: none;
  box-shadow: 0 0 3pt 2pt rgba(0, 0, 0, 0.05);
}

.zoom-img {
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.zoom-img img {
  transition: all 0.5s ease;
}
.zoom-img:hover img {
  transform: scale(1.1);
}

.wp-block-image.ratio img {
  height: 100%;
}
.wp-block-image.ratio a {
  position: absolute;
}
.wp-block-image.mx-out-container img {
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
}

.wp-block-button__link {
  padding: 10px 62px;
  font-size: 14px;
  transition: 0.3s;
  font-family: "arial-bold", sans-serif;
}

.hover-white .wp-block-button__link {
  border: 1px solid #ffffff;
}
.hover-white .wp-block-button__link:hover, .hover-white .wp-block-button__link:focus, .hover-white .wp-block-button__link:active {
  background-color: #0a3255 !important;
  border-color: #ffffff;
  color: #ffffff !important;
}

.hover-secondary .wp-block-button__link {
  border: 1px solid #da262e;
}
.hover-secondary .wp-block-button__link:hover, .hover-secondary .wp-block-button__link:focus, .hover-secondary .wp-block-button__link:active {
  background-color: #ffffff !important;
  color: #da262e !important;
  border-color: #ffffff !important;
}

.hover-primary .wp-block-button__link {
  border: 1px solid #0a3255;
}
.hover-primary .wp-block-button__link:hover, .hover-primary .wp-block-button__link:focus, .hover-primary .wp-block-button__link:active {
  background-color: #ffffff !important;
  color: #0a3255 !important;
  border-color: #0a3255 !important;
}

figure.w-100 img {
  width: 100%;
}
figure.h-100 img {
  height: 100%;
}

input:focus {
  outline: none;
}

body .swiper-button-prev:after,
body .swiper-button-next:after {
  display: none;
}
body .swiper-button-prev:before,
body .swiper-button-next:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.news-item .news-item-image img {
  min-height: 251px;
  max-height: 251px;
  -o-object-fit: cover;
     object-fit: cover;
}
.news-item .news-item-content h3 {
  min-height: 55px;
}

.overlay,
.overlay-dark {
  position: relative;
}
.overlay:before,
.overlay-dark:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.36);
  pointer-events: none;
}

.overlay-dark:before {
  background: rgba(0, 0, 0, 0.64);
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=number] {
  width: 100%;
  padding: 12px 22px;
  color: #0a3255;
  background-color: var(--bs-body-bg);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 5px;
  box-shadow: none;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=number]:focus {
  outline: none;
}
input[type=text]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=password]::-moz-placeholder, input[type=tel]::-moz-placeholder, input[type=number]::-moz-placeholder {
  color: rgba(196, 196, 196, 0.7);
}
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=tel]::placeholder,
input[type=number]::placeholder {
  color: rgba(196, 196, 196, 0.7);
}

hr {
  color: #c4c4c4;
  opacity: 0.3;
  margin: 15px 0;
}

textarea {
  height: 80px;
  width: 100%;
  background-color: var(--bs-body-bg);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 90px;
  padding: 10px 15px;
  font-size: 15px;
  color: #0a3255;
  resize: none;
}
textarea:focus {
  outline: none;
}

input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: 1;
}
input[type=checkbox]:hover,
input[type=radio]:hover {
  outline: none;
}
input[type=checkbox] + label, input[type=checkbox] + span,
input[type=radio] + label,
input[type=radio] + span {
  position: relative;
  cursor: pointer;
  padding: 1px 0 0 28px;
}
input[type=checkbox] + label:focus, input[type=checkbox] + span:focus,
input[type=radio] + label:focus,
input[type=radio] + span:focus {
  outline: none;
}
input[type=checkbox] + label:before, input[type=checkbox] + span:before,
input[type=radio] + label:before,
input[type=radio] + span:before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 17px;
  height: 17px;
  background: #ffffff;
  border: 1px solid #0a3255;
  position: absolute;
  left: 0;
}
input[type=checkbox] + span:before,
input[type=radio] + span:before {
  top: 50%;
  transform: translateY(-50%);
}
input[type=checkbox] + label:before,
input[type=radio] + label:before {
  top: 50%;
  transform: translateY(-50%);
}
input[type=checkbox]:hover + label:before, input[type=checkbox]:hover + span:before,
input[type=radio]:hover + label:before,
input[type=radio]:hover + span:before {
  background: #ffffff;
}
input[type=checkbox]:checked + label:before, input[type=checkbox]:checked + span:before,
input[type=radio]:checked + label:before,
input[type=radio]:checked + span:before {
  background: #0a3255;
}
input[type=checkbox]:disabled + label, input[type=checkbox]:disabled + span,
input[type=radio]:disabled + label,
input[type=radio]:disabled + span {
  color: #ffffff;
  cursor: auto;
}
input[type=checkbox]:disabled + label:before, input[type=checkbox]:disabled + span:before,
input[type=radio]:disabled + label:before,
input[type=radio]:disabled + span:before {
  box-shadow: none;
  background: #ffffff;
}
input[type=checkbox]:checked + label:after, input[type=checkbox]:checked + span:after,
input[type=radio]:checked + label:after,
input[type=radio]:checked + span:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 50%;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

input[type=radio] + span:before {
  border-radius: 50%;
}
input[type=radio]:checked + span:after {
  top: 10px;
}

select {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--bs-white);
  color: var(--bs-secondary);
  padding: 0 15px 0 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 3px;
  height: 40px;
  padding-top: 3px;
}
select:focus-visible {
  outline: none;
}

.scroll_style {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll_style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: #fff;
}
.scroll_style::-webkit-scrollbar {
  width: 5px;
  background-color: #9a9ea6;
}
.scroll_style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: #1acf82;
  box-shadow: inset 0 0 6px transparent;
}

.tooltip {
  font-family: "arial-regular", sans-serif;
  letter-spacing: 2px;
}

.figure-h-100 img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.row-block > .wp-block-group__inner-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
.row-block > .wp-block-group__inner-container > * {
  padding-right: 12px;
  padding-left: 12px;
}

.wp-block-group__inner-container ul {
  list-style-type: none;
  padding-left: 20px;
}

.image-border img {
  padding: 20px;
  border: 1px solid #ffffff;
}

.loading-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
}
.loading-filter.hided {
  display: none !important;
}

.d-flex-block > .wp-block-group__inner-container {
  display: flex;
  flex-wrap: wrap;
}
.d-flex-block .wp-container-1,
.d-flex-block .wp-container-2,
.d-flex-block .wp-block-group__inner-container {
  width: 100%;
}

.has-white-color a {
  color: #ffffff;
}

.wp-block-cover a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px;
}
.wp-block-cover.rounded-1 span,
.wp-block-cover.rounded-1 img {
  border-radius: 5px;
}

.iconify.lh-icon {
  margin-top: -3px;
}

.btn-loadmore {
  padding: 14px 34px;
}

.btn-arrow.padding-3 a {
  padding: 12px 20px;
}
.btn-arrow.padding-4 a {
  padding: 12px 20px;
}
.btn-arrow a {
  display: flex;
  align-items: center;
  padding: 15px 50px;
}
.btn-arrow a img {
  margin-left: 5px;
}
.btn-arrow a .ms-1 {
  margin-left: 0.33335rem !important;
}

.otgs-development-site-front-end {
  display: none;
}

.nav-category-wrapper p {
  margin-bottom: 0;
}

.nav-category-item {
  padding: 22.5px 5px 19.5px 5px;
  color: #ffffff;
  margin: 0 5px;
  text-align: center;
}
.nav-category-item:first-child {
  margin-left: 0;
}
.nav-category-item:last-child {
  margin-right: 0;
}
.nav-category-item:hover {
  color: #60b8d0;
}
.nav-category-item:focus {
  color: #60b8d0;
}
.nav-category-item.active {
  border-bottom: 3px solid #60b8d0;
}

#crumbs {
  color: #0083c1;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
#crumbs svg {
  width: 10px;
  height: 10px;
  margin: 0 6px;
}
#crumbs a,
#crumbs .current {
  display: inline-block;
  padding: 0;
  color: #0083c1;
  font-size: 0.875rem;
  width: unset;
  height: unset;
}

.wpcf7 input {
  border-radius: 0;
  padding: 8px 22px;
}
.wpcf7 select {
  height: 48px;
  color: #0a3255;
  outline: 0;
  font-family: "arial-regular", sans-serif;
}
.wpcf7 .select-wrapper {
  position: relative;
}
.wpcf7 .select-wrapper::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 17px;
  width: 10px;
  height: 10px;
  background: url("root/images/chevron-right.png") no-repeat;
  transform: rotate(90deg);
}
.wpcf7 form.submitting .wpcf7-spinner {
  width: 24px;
  height: 24px;
  margin: 0 24px;
}
.wpcf7 form .wpcf7-spinner {
  width: 0;
  height: 0;
  margin: 0;
}
.wpcf7 form .wpcf7-response-output {
  margin-left: 0;
}

.heading-custom {
  max-width: 985px;
}

.diviner {
  min-height: 30px;
}

.line-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-limit-1 {
  -webkit-line-clamp: 1;
}
.line-limit-2 {
  -webkit-line-clamp: 2;
}
.line-limit-3 {
  -webkit-line-clamp: 3;
}
.line-limit-4 {
  -webkit-line-clamp: 4;
}

.homepage-banner {
  position: relative;
}
.homepage-banner .wp-block-cover__background,
.homepage-banner .wp-block-cover__image-background {
  margin-left: calc(50% - 50vw) !important;
  margin-right: calc(50% - 50vw) !important;
  width: 100vw !important;
}
.homepage-banner ~ .wp-block-image.mx-out-container img {
  display: block;
  height: 24px;
}

.home .properties-range-widget .wp-block-column,
.who-we-are__container .properties-range-widget .wp-block-column {
  padding: 45px 30px;
  position: relative;
}
.home .properties-range-widget .wp-block-column .wp-block-image,
.who-we-are__container .properties-range-widget .wp-block-column .wp-block-image {
  margin-bottom: 24px;
}
.home .properties-range-widget .wp-block-column h5,
.who-we-are__container .properties-range-widget .wp-block-column h5 {
  font-size: 24px;
}
.home .properties-range-widget .wp-block-column > p,
.who-we-are__container .properties-range-widget .wp-block-column > p {
  font-size: 32px;
}
.home .properties-range-widget .wp-block-column:nth-child(1) > p,
.who-we-are__container .properties-range-widget .wp-block-column:nth-child(1) > p {
  color: #d7282f;
}
.home .properties-range-widget .wp-block-column:nth-child(3) > p,
.who-we-are__container .properties-range-widget .wp-block-column:nth-child(3) > p {
  color: #d7282f;
}

.list-number-custom {
  counter-reset: item;
  list-style-type: none;
  padding-left: 25px;
  margin-bottom: 0;
}
.list-number-custom li {
  display: block;
  position: relative;
}
.list-number-custom li:not(:last-child) {
  margin-bottom: 24px;
}
.list-number-custom li:before {
  content: counter(item) "  ";
  counter-increment: item;
  background-color: #da262e;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  left: -25px;
  top: 2px;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
}

.home .homepage-banner .wp-block-cover {
  height: calc(100vh - 100px);
}
.home .homepage-banner .wp-block-image {
  position: absolute;
  height: 50%;
  margin-top: 40px;
}
.home .homepage-banner .wp-block-image::after {
  content: "";
  position: absolute;
  top: 0;
  background-color: #fff;
  width: 1px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  mix-blend-mode: multiply;
}
.home .homepage-banner .wp-block-image a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
}
.home .home_tab {
  font-size: 18px;
}
.home .home_tab h1,
.home .home_tab h2,
.home .home_tab h3,
.home .home_tab h4,
.home .home_tab h5 {
  font-size: 40px;
  margin-bottom: 32px;
}
.home .home_tab a.read-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 225px;
  background-color: #0a3255;
  color: #ffffff;
}
.home .home_tab a.read-more img {
  margin-left: 10px;
}
.home .home_tab span[toggle-for] {
  color: rgba(10, 50, 85, 0.5);
  font-size: 20px;
  padding-bottom: 16px;
}
.home .home_tab span[toggle-for]:first-child {
  margin-right: 32px;
}
.home .home_tab span[toggle-for].active {
  font-family: "arial-bold", sans-serif;
  color: #0a3255;
  font-weight: 700;
  border-bottom: 2px #d7282f solid;
}
.home .home_product_section {
  flex-wrap: wrap !important;
  gap: 0 !important;
}
.home .home_product_section > * {
  max-height: 720px;
}
.home .home_product_section > div:not(:first-child) {
  margin-left: 4px;
}
.home .home_product_section > div:not(:first-child) .wp-block-image {
  margin-bottom: 0;
  height: 100%;
}
.home .home_product_section > div:not(:first-child) .wp-block-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
  width: 100%;
  height: 100%;
}
.home .home_product_section > div:nth-child(2) h3 {
  font-size: 40px;
  margin-bottom: 40px;
}
.home .home_product_section > div:nth-child(2) p {
  font-size: 18px;
  margin-bottom: 30px;
}
.home .home_product_section > div:nth-child(2) .wp-block-button__link {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 225px;
  width: 225px;
  height: 56px;
  padding: 0;
}
.home .home_product_section > div:nth-child(2) .wp-block-button__link img {
  margin-left: 10px;
}
.home .home_product_section > div:not(:first-child):not(:nth-child(2)) {
  position: relative;
}
.home .home_product_section > div:not(:first-child):not(:nth-child(2)) p {
  position: absolute;
  left: 48px;
  bottom: 50px;
  font-size: 32px;
  font-weight: 700;
  font-family: "arial-bold", sans-serif;
  color: #ffffff;
}
.home .home_product_section > div:not(:first-child):not(:nth-child(2)) p a {
  color: #ffffff;
}
.home .properties {
  margin-bottom: 50px;
}
.home .properties h3 {
  font-size: 40px;
}
.home .properties p {
  font-size: 18px;
}
.home .properties .wp-block-button__link {
  height: 56px;
  display: flex;
  align-items: center;
}
.home .properties .wp-block-button__link img {
  margin-left: 10px;
}
.home .swiper-button-wrapper {
  width: 128px;
  height: 56px;
  display: flex;
  justify-content: space-between;
}
.home .swiper-button-wrapper > div {
  width: 56px;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  position: relative;
  left: unset;
  right: unset;
  border: 1px solid #d0cfcd;
  top: unset;
  margin-top: unset;
  position: relative;
}
.home #swiper-slider-news .news-image {
  min-height: 250px;
  max-height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.home #swiper-slider-news .card-body {
  padding: 48px 40px 37px 40px;
}

.search .news-list::before {
  content: "";
  position: absolute;
  left: -100%;
  right: -100%;
  bottom: 0;
  height: 1px;
  background-color: #d0cfcd;
  z-index: -1;
}
.search .news-list li a {
  display: block;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.search .news-list li a.active, .search .news-list li a:focus {
  color: #0a3255;
  opacity: 1;
  font-weight: bold;
  border-bottom: 2px solid #d7282f;
}
.search .news-item .news-item-image img {
  min-height: 251px;
  max-height: 251px;
  -o-object-fit: cover;
     object-fit: cover;
}
.search .news-item .news-item-content h3 {
  min-height: 55px;
}
.search .properties-item ul {
  background-color: unset;
}
.search .properties-item .news-item {
  margin-bottom: 48px;
}
.search .properties-item .news-item h3,
.search .properties-item .news-item li {
  color: #0a3255;
  transition: 0.5s all ease-in-out;
}
.search .properties-item .news-item .news-item-highlight {
  transform-origin: 100% 0px;
  transform: rotate(45deg) translate(50%, -1px);
}
.search .properties-item .news-item .news-item-content {
  transition: 0.5s all ease-in-out;
}
.search .properties-item .news-item .news-item-image img {
  transition: 0.5s all ease-in-out;
}
.search .properties-item .news-item:hover .news-item-image img {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}
.search .properties-item .news-item:hover .news-item-content {
  transition: 0.5s all ease-in-out;
  background-color: #0a3255;
}
.search .properties-item .news-item:hover h3,
.search .properties-item .news-item:hover li {
  transition: 0.5s all ease-in-out;
  color: #ffffff;
}
.search .properties-item .news-item:hover h3 a,
.search .properties-item .news-item:hover li a {
  color: #ffffff;
  transition: 0.5s all ease-in-out;
}
.search .properties-item .news-item:hover li {
  border-bottom: 1px solid #0083c1;
  transition: 0.5s all ease-in-out;
}
.search .properties-item .list-group {
  background-color: transparent;
}
.search .properties-item .list-group li {
  background-color: transparent;
  line-height: 60px;
  box-shadow: unset;
  border: 0;
  border-bottom: 1px solid #d0cfcd;
  transition: 0.5s all ease-in-out;
}
.search .properties-item .list-group li strong,
.search .properties-item .list-group li span {
  font-size: 18px;
}
.search .careers-item:last-child {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}
.search .search-query {
  padding: 0 24px;
  height: 64px;
  background-color: rgba(208, 207, 205, 0.4);
  margin-bottom: 48px;
  margin-top: 26px;
}
.search .nav_wrapper {
  margin-bottom: 48px;
}
.search #myTab {
  border: unset;
  position: relative;
  gap: 32px;
}
.search #myTab button {
  border: unset;
  background-color: unset;
  box-shadow: none;
  padding: 0;
  padding-bottom: 16px;
  text-transform: capitalize;
  color: rgba(10, 50, 85, 0.5);
  font-size: 20px;
  font-weight: 700;
  font-family: "arial-bold", sans-serif;
}
.search #myTab button.active {
  border-bottom: 2px solid #d7282f;
  color: #0a3255;
}
.search #myTabContent {
  margin-bottom: 80px;
}
@media (max-width: 1199.98px) {
  .home .home_product_section > *:not(:first-child) {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 !important;
    margin-bottom: 4px !important;
    padding: 0 2px;
  }
}
@media (max-width: 1599.98px) {
  .home .properties-range-widget .wp-block-columns,
.who-we-are__container .properties-range-widget .wp-block-columns {
    flex-wrap: wrap !important;
    gap: 0 !important;
  }
  .home .properties-range-widget .wp-block-column,
.who-we-are__container .properties-range-widget .wp-block-column {
    padding: 30px 30px 10px;
    flex-basis: 50% !important;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .home .properties-range-widget .wp-block-column h5,
.who-we-are__container .properties-range-widget .wp-block-column h5 {
    font-size: 24px;
  }
  .home .properties-range-widget .wp-block-column > p,
.who-we-are__container .properties-range-widget .wp-block-column > p {
    font-size: 30px;
  }
}
.modal .modal-content {
  max-width: 684px;
  margin: 0 auto;
  border-radius: 0;
  padding: 40px 32px;
}
.modal#register-form label {
  font-family: "arial-bold", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
  width: 100%;
}
.modal#register-form input[type=date] {
  width: 100%;
  padding: 12px 22px;
  color: #0a3255;
  background-color: var(--bs-body-bg);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 5px;
  box-shadow: none;
}
.modal#register-form input[type=date]:focus {
  outline: none;
}
.modal#register-form input[type=date]::-moz-placeholder {
  color: rgba(196, 196, 196, 0.7);
}
.modal#register-form input[type=date]::placeholder {
  color: rgba(196, 196, 196, 0.7);
}
.modal#register-form .wpcf7-checkbox .wpcf7-list-item {
  position: relative;
  margin-left: 0;
  margin-bottom: 4px;
  display: block;
}
.modal#register-form .wpcf7-checkbox .wpcf7-list-item input[type=checkbox],
.modal#register-form .wpcf7-checkbox .wpcf7-list-item input[type=radio] {
  top: 0;
  width: 17px;
  height: 20px;
}

@media (max-width: 991.98px) {
  .home .properties-range-widget .wp-block-column,
.who-we-are__container .properties-range-widget .wp-block-column {
    padding: 15px 10px;
  }
}
@media (max-width: 575.98px) {
  .home .home_tab h3 {
    font-size: 32px;
  }
  .home .home_product_section > div:nth-child(2) h3 {
    font-size: 32px;
  }
  .home .properties h3 {
    font-size: 32px;
  }
  .home .home_product_section > *:not(:first-child) {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 4px;
    padding: 0 2px;
  }
  .home .home_product_section > *:not(:first-child):not(.block_home_product) {
    max-height: 370px;
  }
  .home .properties-range-widget .wp-block-column h5,
.who-we-are__container .properties-range-widget .wp-block-column h5 {
    font-size: 20px;
  }
  .home .properties-range-widget .wp-block-column > p,
.who-we-are__container .properties-range-widget .wp-block-column > p {
    font-size: 24px;
  }
}
@media (max-width: 1599.98px) {
  .home .home_product_section > * {
    max-height: 550px;
  }
  .home .home_product_section > *:first-child img {
    height: 550px;
  }
}